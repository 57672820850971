<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>{{ action }} Region</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 200px">
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    :error-messages="errors.get('name')"
                    required
                    label="Name"
                    v-model="form.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-autocomplete
                    :error-messages="errors.get('country_id')"
                    required
                    clearable
                    :items="countries"
                    item-text="name"
                    item-value="id"
                    label="Country"
                    v-model="form.country_id"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain @click="close" large> Cancel </v-btn>
          <v-btn
            color="teal lighten-1"
            class="white--text"
            :disabled="updating"
            depressed
            @click="save"
            large
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import FormErrors from "./../helpers/form-errors";
export default {
  data: () => ({
    form: {},
    errors: new FormErrors(),
    dialog: false,
    updating: false,
    action: "Create",
  }),
  created() {
    this.form = this.initForm();
  },
  computed: {
    ...mapState({
      countries: (state) => state.countries.countries,
    }),
  },
  methods: {
    save() {
      if (this.action == "Create") {
        this.store();
      } else {
        this.update();
      }
    },
    edit(item) {
      this.action = "Edit";
      this.dialog = true;
      this.updating = true;
      window.axios.get(`/api/regions/${item.id}`).then((r) => {
        this.updating = false;
        this.form = {
          id: r.data.id,
          name: r.data.name,
          country_id: r.data.country_id,
        };
      });
    },
    update() {
      this.updating = true;
      window.axios
        .put(`/api/regions/${this.form.id}`, this.form)
        .then(() => {
          this.updating = false;
          this.$toast.success("Region updated.");
          this.close();
          this.$emit("reload");
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$toast.error("Please complete all required fields.");
            this.errors.record(e.response.data.errors);
          }
          this.updating = false;
        });
    },
    store() {
      this.updating = true;
      window.axios
        .post(`/api/regions`, this.form)
        .then(() => {
          this.updating = false;
          this.$toast.success("Region created.");
          this.close();
          this.$emit("reload");
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$toast.error("Please complete all required fields.");
            this.errors.record(e.response.data.errors);
          }
          this.updating = false;
        });
    },
    close() {
      this.errors.clear();
      this.dialog = false;
      this.form = this.initForm();
      this.action = "Create";
    },
    initForm() {
      return {
        id: null,
        name: "",
        country_id: "",
      };
    },
  },
};
</script>