<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      width="800px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>{{ title }} Clinic</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 520px" class="pt-5">
          <v-tabs v-model="current_tab" v-if="action == 'Create'">
            <v-tab :key="0">Clinic Info</v-tab>
            <v-tab :key="1">Clinic User</v-tab>
          </v-tabs>
          <v-tabs-items v-model="current_tab">
            <v-tab-item :key="0" v-if="form.clinic">
              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-text-field
                        :disabled="viewing"
                        :error-messages="errors.get('clinic.name')"
                        required
                        label="Name"
                        v-model="form.clinic.name"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-text-field
                        :disabled="viewing"
                        :error-messages="errors.get('clinic.email')"
                        required
                        label="Email"
                        v-model="form.clinic.email"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-text-field
                        :disabled="viewing"
                        label="Phone"
                        v-model="form.clinic.phone"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <v-text-field
                        :disabled="viewing"
                        label="Address Line 1"
                        v-model="form.clinic.address_1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <v-text-field
                        :disabled="viewing"
                        label="Address Line 2"
                        v-model="form.clinic.address_2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <v-text-field
                        :disabled="viewing"
                        label="Postal Code"
                        v-model="form.clinic.postal_code"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <v-text-field
                        :disabled="viewing"
                        label="City"
                        v-model="form.clinic.city"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-autocomplete
                        :disabled="viewing"
                        :error-messages="errors.get('clinic.region_id')"
                        required
                        :items="regions"
                        item-text="name"
                        item-value="id"
                        label="Region"
                        v-model="form.clinic.region_id"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <v-text-field
                        :disabled="viewing"
                        label="Sort Order"
                        v-model="form.clinic.sort_order"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-tab-item>
            <v-tab-item :key="1" v-if="form.user && action == 'Create'">
              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="6" class="pb-0">
                      <v-text-field
                        :disabled="viewing"
                        :error-messages="errors.get('user.last_name')"
                        required
                        label="Last Name"
                        v-model="form.user.last_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                      <v-text-field
                        :disabled="viewing"
                        :error-messages="errors.get('user.first_name')"
                        required
                        label="First Name"
                        v-model="form.user.first_name"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="pb-0">
                      <v-text-field
                        :disabled="viewing"
                        :error-messages="errors.get('user.username')"
                        required
                        label="Username"
                        v-model="form.user.username"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                      <v-text-field
                        :disabled="viewing"
                        :error-messages="errors.get('user.email')"
                        required
                        label="Email"
                        v-model="form.user.email"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="pb-0">
                      <v-text-field
                        :disabled="viewing"
                        :error-messages="errors.get('user.password')"
                        required
                        type="password"
                        label="Password"
                        v-model="form.user.password"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                      <v-text-field
                        :disabled="viewing"
                        type="password"
                        label="Confirm Password"
                        v-model="form.user.password_confirmation"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain @click="close" large> Cancel </v-btn>
          <v-btn
            v-if="viewing"
            color="amber darken-2"
            class="white--text"
            depressed
            @click="viewing = false"
            large
            :disabled="updating"
          >
            Edit
          </v-btn>
          <v-btn
            v-else
            color="teal lighten-1"
            class="white--text"
            depressed
            :disabled="updating"
            @click="save"
            large
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import FormErrors from "./../helpers/form-errors";
export default {
  computed: {
    ...mapState({
      regions: (state) => state.regions.regions,
      authUser: (state) => state.authUser.user,
    }),
    title() {
      if (this.viewing) {
        return "Details: ";
      }
      return this.action;
    },
  },
  data() {
    return {
      current_tab: 0,
      dialog: false,
      form: {},
      errors: new FormErrors(),
      updating: false,
      action: "Create",
      viewing: false,
    };
  },
  created() {
    this.form = this.initForm();
  },
  methods: {
    save() {
      if (this.action == "Create") {
        this.store();
      } else {
        this.update();
      }
    },
    edit(item) {
      this.action = "Edit";
      this.dialog = true;
      this.updating = true;
      window.axios.get(`/api/clinics/${item.id}`).then((r) => {
        this.updating = false;
        this.form.clinic = {
          id: r.data.id,
          name: r.data.name,
          email: r.data.email,
          phone: r.data.phone,
          address_1: r.data.address_1,
          address_2: r.data.address_2,
          postal_code: r.data.postal_code,
          city: r.data.city,
          sort_order: r.data.sort_order,
          region_id: r.data.region_id,
        };
      });
    },
    update() {
      this.updating = true;
      this.form.clinic.updated_by = this.authUser.id;
      console.log(this.form);
      window.axios
        .put(`/api/clinics/${this.form.clinic.id}`, this.form)
        .then(() => {
          this.updating = false;
          this.$toast.success("Clinic updated.");
          this.close();
          this.$emit("reload");
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$toast.error("Please complete all required fields.");
            this.errors.record(e.response.data.errors);
          }
          this.updating = false;
        });
    },
    store() {
      this.updating = true;
      this.form.clinic.created_by = this.authUser.id;
      this.form.user.created_by = this.authUser.id;
      window.axios
        .post(`/api/clinics`, this.form)
        .then(() => {
          this.updating = false;
          this.$toast.success("Clinic created.");
          this.close();
          this.$emit("reload");
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$toast.error("Please complete all required fields.");
            this.errors.record(e.response.data.errors);
          }
          this.updating = false;
        });
    },
    close() {
      this.errors.clear();
      this.dialog = false;
      this.form = this.initForm();
      this.action = "Create";
    },
    initForm() {
      return {
        clinic: {
          id: null,
          name: "",
          email: "",
          phone: "",
          address_1: "",
          address_2: "",
          postal_code: "",
          city: "",
          region_id: "",
          sort_order: "",
        },
        user: {
          id: null,
          first_name: "",
          last_name: "",
          email: "",
          username: "",
          password: "",
          password_confirmation: "",
        },
      };
    },
  },
};
</script>

<style>
</style>
