var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":"","mini-variant":_vm.mini,"color":"grey lighten-3"},model:{value:(_vm.drawerVal),callback:function ($$v) {_vm.drawerVal=$$v},expression:"drawerVal"}},[_c('v-sheet',{staticClass:"d-block text-center mx-auto mt-6 mb-6",attrs:{"color":"grey lighten-3"}},[(!_vm.mini)?_c('span',{staticClass:"h1"},[_vm._v("  Fetch")]):_c('span',{staticClass:"h1"},[_vm._v(" ")])]),_c('v-divider',{staticClass:"mx-3 my-5"}),_c('v-list',[_vm._l((_vm.dynamicLinks),function(ref){
var icon = ref[0];
var text = ref[1];
var link = ref[2];
return [_c('v-tooltip',{key:text,attrs:{"right":"","disabled":!_vm.mini},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({key:icon,attrs:{"to":link}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(text))])])]}),_c('v-list-item',{staticClass:"collapse-menu",on:{"click":function($event){_vm.mini = !_vm.mini}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chevron-"+_vm._s(_vm.mini ? "right" : "left"))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Collapse")])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }