export default {
  state: {
    dispositions: [],
  },
  mutations: {
    setDispositions(state, payload) {
      state.dispositions = payload.dispositions;
    },
  },
  actions: {
    getDispositions: ({ commit }, params) => {
      return new Promise((resolve, reject) => {
        window.axios
          .post(`/api/dispositions/filter`, params)
          .then((r) => {
            commit({
              type: "setDispositions",
              dispositions: r.data.data,
            });

            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
