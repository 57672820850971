<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>Upload URN image</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 80px">
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-file-input
                    :error-messages="errors.get('image')"
                    required
                    chips
                    v-model="form.image"
                    accept="image/*"
                    label="Browse"
                    prepend-icon="mdi-camera"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain @click="close" large> Cancel </v-btn>
          <v-btn
            color="teal lighten-1"
            class="white--text"
            :disabled="updating"
            depressed
            @click="save"
            large
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import FormErrors from "./../helpers/form-errors";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      authUser: (state) => state.authUser.user,
    }),
  },
  data: () => ({
    form: {},
    errors: new FormErrors(),
    dialog: false,
    updating: false,
    action: "Create",
  }),
  created() {
    this.form = this.initForm();
  },
  methods: {
    save() {
      this.store();
    },
    store() {
      this.updating = true;
      const formData = new FormData();
      for (const key in this.form) {
        formData.append(key, this.form[key]);
      }
      window.axios
        .post(`/api/urns/replace-image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.updating = false;
          this.$toast.success("Urn image uploaded.");
          this.close();
          this.$emit("reload");
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$toast.error("Please complete all required fields.");
            this.errors.record(e.response.data.errors);
          }
          this.updating = false;
        });
    },
    close() {
      this.errors.clear();
      this.dialog = false;
      this.form = this.initForm();
    },
    initForm() {
      return {
        id: null,
        image: undefined,
      };
    },
  },
};
</script>