<template>
  <div>
    <v-card flat class="mb-5" v-show="filter">
      <v-form>
        <v-row>
          <v-col cols="6" class="pb-0" xs="12">
            <v-text-field
              clearable
              dense
              outlined
              label="Name"
              v-model="form.name"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pb-0" xs="12">
            <v-autocomplete
              clearable
              dense
              outlined
              :items="statuses"
              label="Status"
              item-text="title"
              item-value="title"
              v-model="form.status"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              class="mr-5"
              color="blue-grey darken-1"
              dark
              depressed
              @click="search"
              >Search</v-btn
            >
            <v-btn dark color="grey" depressed outlined @click="resetFilter"
              >Reset Filter</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="urns"
      :options.sync="options"
      :server-items-length="totalUrns"
      :footer-props="footerProps"
      :loading="loading"
    >
      <template v-slot:item.is_active="{ item }">
        <record-activator
          :item="item"
          table="urns"
          @reload="paginate"
        ></record-activator>
      </template>
      <template v-slot:item.image="{ item }">
        <v-card outlined>
          <v-img
            style="cursor: pointer"
            @click="viewImage(item)"
            :src="`${publicUrl}/images/urns/${item.image}`"
            aspect-ratio="1"
            class="grey lighten-2"
            max-height="200"
            max-width="200"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
              </v-row>
            </template>
          </v-img>
          <v-card-actions>
            <template v-if="item.image">
              <v-btn
                color="red lighten-2"
                small
                dark
                @click="removeImage(item)"
              >
                Remove
              </v-btn>
              <v-btn
                color="grey light-2"
                small
                dark
                @click="replaceImage(item)"
              >
                Replace
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                color="grey light-2"
                small
                dark
                @click="replaceImage(item)"
              >
                Upload
              </v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="amber darken-2"
              v-bind="attrs"
              v-on="on"
              @click="edit(item)"
            >
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="red"
              v-bind="attrs"
              v-on="on"
              @click="remove(item)"
            >
              <v-icon>mdi-close-box-outline</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <create-urn ref="createUrnDialog" @reload="paginate"></create-urn>
    <replace-image ref="replaceImageDialog" @reload="paginate"></replace-image>
    <urn-image-viewer ref="urnImageViewerDialog"></urn-image-viewer>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import createUrn from "../../../components/create-urn.vue";
import RecordActivator from "../../../components/record-activator.vue";
import ReplaceImage from "../../../components/replace-image.vue";
import UrnImageViewer from "../../../components/urn-image-viewer.vue";
export default {
  components: { createUrn, RecordActivator, ReplaceImage, UrnImageViewer },
  data() {
    return {
      headers: [
        { text: "", value: "is_active", width: "50" },
        { text: "Image", value: "image" },
        { text: "Name", value: "name" },
        { text: "Price", value: "price" },
        { text: "Description", value: "description" },
        { text: "Sort Order", value: "sort_order" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: 110,
        },
      ],
      loading: true,
      footerProps: {
        itemsPerPageOptions: [25, 50, 100],
      },
      totalUrns: 0,
      options: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 100,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: ["sort_order"],
        sortDesc: [false],
      },
      filter: false,
      form: {},
      statuses: ["Active", "Inactive"],
    };
  },
  watch: {
    options: {
      handler() {
        this.paginate(this.form);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      urns: (state) => state.urns.urns,
    }),
    publicUrl() {
      return process.env.VUE_APP_API_URL;
    },
  },
  methods: {
    ...mapActions(["getUrns"]),
    viewImage(urn) {
      this.$refs.urnImageViewerDialog.image = urn.image;
      this.$refs.urnImageViewerDialog.dialog = true;
    },
    removeImage(urn) {
      this.$confirm("Do you really want to delete this urn image?", {
        color: "error",
        title: "Warning",
      }).then((res) => {
        if (res) {
          window.axios
            .post(`/api/urns/remove-image`, { id: urn.id })
            .then(() => {
              this.paginate();
            });
        }
      });
    },
    replaceImage(urn) {
      this.$refs.replaceImageDialog.form.id = urn.id;
      this.$refs.replaceImageDialog.dialog = true;
    },
    resetFilter() {
      this.form = this.initForm();
      this.paginate();
    },
    search() {
      this.paginate(this.form);
    },
    initForm() {
      return {
        name: "",
        status: "",
      };
    },
    paginate(params = {}) {
      this.loading = true;
      this.getUrns({ ...this.options, ...params }).then((r) => {
        this.totalUrns = +r.data.total;
        this.options.itemsPerPage = +r.data.per_page;
        this.loading = false;
      });
    },
    edit(item) {
      this.$refs.createUrnDialog.edit(item);
    },
    remove(item) {
      this.$confirm("Do you really want to delete this urn?", {
        color: "error",
        title: "Warning",
      }).then((res) => {
        if (res) {
          window.axios.delete(`/api/urns/${item.id}`).then(() => {
            this.paginate(this.form);
            this.$toast.success("Urn deleted.");
          });
        }
      });
    },
  },
};
</script>