<template>
  <div>
    <v-card flat class="mb-5">
      <v-row>
        <v-col cols="12" v-if="!filter">
          <v-row>
            <v-col cols="4" class="pb-0" v-if="!isAuthClinicUser">
              <v-autocomplete
                outlined
                :items="regions"
                item-text="name"
                item-value="id"
                label="Region"
                v-model="form.region_id"
                clearable
                dense
                @change="search"
              ></v-autocomplete>
            </v-col>
            <v-col :cols="!isAuthClinicUser ? 8 : 12" class="pb-0">
              <v-text-field
                label="Search Pet Name"
                outlined
                dense
                clearable
                v-model="form.name"
                @keyup.enter="search"
                @click:clear="simpleSearchClear"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card flat class="mb-5" v-show="filter">
      <v-form>
        <v-row v-if="!isAuthClinicUser">
          <v-col cols="6" class="pb-0" xs="12">
            <v-autocomplete
              clearable
              hide-details
              dense
              outlined
              :items="regions"
              item-text="name"
              item-value="id"
              label="Region"
              v-model="form.region_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" class="pb-0" xs="12">
            <v-autocomplete
              clearable
              dense
              outlined
              :items="clinics"
              label="Clinic"
              item-text="name"
              item-value="id"
              v-model="form.clinic_id"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" class="pb-0" xs="12">
            <v-text-field
              clearable
              dense
              outlined
              label="Pet First Name"
              v-model="form.name"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pb-0" xs="12">
            <v-text-field
              clearable
              dense
              outlined
              label="Pet Last Name"
              v-model="form.pet_last_name"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" class="pb-0" xs="12">
            <v-autocomplete
              clearable
              dense
              outlined
              multiple
              chips
              deletable-chips
              :items="urns"
              label="URN Type"
              item-text="name"
              item-value="id"
              v-model="form.urn_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" class="pb-0" xs="12">
            <v-autocomplete
              clearable
              dense
              outlined
              :items="dispositions"
              label="Disposition"
              item-text="name"
              item-value="id"
              v-model="form.disposition_id"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3" class="pb-0" xs="12">
            <v-autocomplete
              clearable
              dense
              outlined
              :items="date_types"
              label="Date Type"
              item-text="label"
              item-value="field"
              v-model="form.date_type"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3" class="pb-0" xs="12">
            <v-menu
              v-model="menu_date_from"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  clearable
                  dense
                  outlined
                  v-model="form.date_from"
                  label="From"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.date_from"
                @input="menu_date_from = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3" class="pb-0" xs="12">
            <v-menu
              v-model="menu_date_to"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  clearable
                  dense
                  outlined
                  v-model="form.date_to"
                  label="To"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.date_to"
                @input="menu_date_to = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3" class="pb-0" xs="12" v-if="!isAuthReceiver">
            <v-autocomplete
              clearable
              dense
              outlined
              :items="statuses"
              label="Status"
              item-text="title"
              item-value="title"
              v-model="form.status"
              @change="parentChangeStatus"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              class="mr-5"
              color="blue-grey darken-1"
              dark
              depressed
              @click="search"
              >Search</v-btn
            >
            <v-btn dark color="grey" depressed outlined @click="resetFilter"
              >Reset Filter</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <v-data-table
      :headers="dynamicHeaders"
      :items="orders"
      :options.sync="options"
      :server-items-length="totalOrders"
      :footer-props="footerProps"
      :loading="loading"
    >
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate }}
      </template>
      <template v-slot:item.name="{ item }">
        <p
          class="teal--text text--darken-2"
          style="cursor: pointer; font-size: 14px; font-weight: 500"
          @click="edit(item, false)"
        >
          {{ item.name }}
        </p>
      </template>
      <template v-slot:item.pet_last_name="{ item }">
        {{ item.pet_last_name }}
      </template>
      <template v-slot:item.special_note="{ item }">
        <p style="padding: 10px; white-space: pre-wrap">
          {{ item.special_note }}
        </p>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="isAuthClinicUser && item.status >= 2"
              icon
              color="amber darken-2"
              v-bind="attrs"
              v-on="on"
              @click="edit(item)"
            >
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="isAuthClinicUser && item.status >= 2"
              icon
              color="red"
              v-bind="attrs"
              v-on="on"
              @click="remove(item)"
            >
              <v-icon>mdi-close-box-outline</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="
                isAuthClinicUser && item.status >= 2 && item.status < 6
              "
              :color="getStatusColor(+item.status)"
              small
              class="white--text"
              v-bind="attrs"
              v-on="on"
            >
              {{ getStatusName(+item.status) }}
            </v-btn>
          </template>
          <v-list v-if="canUpdateStatus(item.status)">
            <v-list-item
              link
              @click="updateStatus(item, status.id)"
              v-for="(status, index) in statusOptions(item.status)"
              :key="index"
            >
              <v-list-item-title>{{ status.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.weight="{ item }">
        <v-btn
          color="teal"
          small
          dark
          @click="weightIn(item)"
          v-if="
            (item.weight === null || item.weight === 0 || item.weight === '') &&
            isAuthReceiver
          "
        >
          ADD WEIGHT
        </v-btn>
        <template v-else>
          <span v-text="item.weight"></span>
          <v-btn
            v-if="[0, 1, 2].includes(item.status) && isAuthReceiver"
            color="orange"
            text
            small
            dark
            @click="weightIn(item)"
          >
            <v-icon>mdi-square-edit-outline</v-icon> UPDATE WEIGHT
          </v-btn>
        </template>
      </template>
      <template v-slot:item.pickedup_at="{ item }">
        <v-btn
          color="teal"
          small
          dark
          @click="showDriverConfirmationAlert(item, 'pickup')"
          v-if="!item.pickedup_at"
        >
          PICK UP
        </v-btn>
        <template v-else>
          {{ item.pickedup_at | formatDate }}
        </template>
      </template>
      <template v-slot:item.droppedoff_at="{ item }">
        <v-btn
          color="blue-grey"
          small
          dark
          @click="showDriverConfirmationAlert(item, 'dropoff')"
          v-if="!item.droppedoff_at && item.status >= 4"
        >
          DROP OFF
        </v-btn>
        <template v-else>
          {{ item.droppedoff_at | formatDate }}
        </template>
      </template>
      <template v-slot:item.urn_type="{ item }">
        {{ displayUrn(item.urns) }}
      </template>
    </v-data-table>
    <create-order
      ref="createOrderDialog"
      @reload="paginate(form)"
    ></create-order>
    <weight-in ref="weightInDialog" @reload="paginate(form)"></weight-in>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import createOrder from "../../../components/create-order.vue";
import WeightIn from "../../../components/weight-in.vue";
import { format } from "date-fns";

const headers = [
  { text: "Created", value: "created_at" },
  { text: "First Name", value: "name", width: 100 },
  { text: "Last Name", value: "pet_last_name", width: 100 },
  { text: "Picked Up", value: "pickedup_at" },
  { text: "Weight", value: "weight", width: 50 },
  { text: "Dropped Off", value: "droppedoff_at" },
  { text: "Disposition", value: "disposition" },
  { text: "Urn Type", value: "urn_type", sortable: false },
  { text: "Status", value: "status", align: "center" },
  { text: "Employee", value: "employee" },
  { text: "Client", value: "client" },
  {
    text: "Special Note",
    value: "special_note",
    sortable: false,
    width: 250,
  },
  // { text: "Invoice #", value: "invoice_number" },
  {
    text: "Actions",
    value: "actions",
    sortable: false,
    width: 110,
    align: "center",
  },
];

export default {
  components: { createOrder, WeightIn },
  data() {
    return {
      headers,
      defaultHeaders: [
        "created_at",
        "name",
        "pet_last_name",
        "disposition",
        "urn_type",
        "status",
        "client",
        "actions",
      ],
      loading: true,
      footerProps: {
        itemsPerPageOptions: [25, 50, 100],
        disableItemsPerPage: true,
      },
      totalOrders: 0,
      options: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 100,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: [],
      },
      statuses: [],
      form: {},
      menu_date_from: false,
      menu_date_to: false,
      filter: false,
      hideOnHold: true,
      date_types: [
        {
          label: "Created",
          field: "created_at",
        },
        {
          label: "Picked Up",
          field: "pickedup_at",
        },
        {
          label: "Dropped Off",
          field: "droppedoff_at",
        },
      ],
      region_id: null,
      search_term: "",
    };
  },
  created() {
    this.form = this.initForm();
  },
  mounted() {
    this.getStatuses();
  },
  watch: {
    options: {
      handler() {
        this.paginate(this.form);
      },
      deep: true,
    },
    "form.date_type": {
      handler() {
        this.changeTableHeaders();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders.orders,
      urns: (state) => state.urns.urns,
      clinics: (state) => state.clinics.clinics,
      regions: (state) => state.regions.regions,
      dispositions: (state) => state.dispositions.dispositions,
      total_weight: (state) => state.orders.total_weight,
    }),
    ...mapGetters([
      "isAuthAdmin",
      "isAuthClinicUser",
      "isAuthOperator",
      "isAuthDriver",
      "isAuthReceiver",
    ]),

    dynamicHeaders() {
      let newHeaders = JSON.parse(JSON.stringify(this.defaultHeaders));
      if (this.isAuthAdmin) {
        newHeaders.push("client", "special_note", "weight");
      }
      if (this.isAuthClinicUser) {
        newHeaders.push("special_note", "weight");
        newHeaders = newHeaders.filter((header) => header !== "client");
      }
      if (this.isAuthDriver) {
        newHeaders = newHeaders.filter((header) => header !== "status");
        newHeaders.push(
          "pickedup_at",
          "weight",
          "special_note",
          "droppedoff_at"
        );
      }
      if (this.isAuthReceiver) {
        newHeaders.push("client", "special_note", "weight");
      }

      if (this.isAuthOperator) {
        newHeaders.push(
          "pickedup_at",
          "client",
          "special_note",
          "weight",
        );

        // insert droppedoff_at if cond is true
        if (this.form.date_type === "droppedoff_at") {
          newHeaders.push("droppedoff_at");
        }
      }
      return this.headers.filter((o) => {
        return newHeaders.includes(o.value);
      });
    },
  },
  methods: {
    ...mapActions(["getOrders", "getTotalWeight"]),
    ...mapMutations(["setExportDone", "setExporting"]),
    canUpdateStatus(status) {
      if (this.isAuthAdmin || this.isAuthDriver || this.isAuthOperator) {
        return true;
      }
      if (this.isAuthClinicUser && ([0, 1].includes(status) || status >= 6)) {
        return true;
      }

      return false;
    },
    statusOptions(status) {
      if (this.isAuthClinicUser && [0, 1].includes(status)) {
        return this.statuses.filter((o) => [0, 1].includes(o.id));
      } else if (this.isAuthClinicUser && status >= 6) {
        return this.statuses.filter((o) => o.id >= 6);
      }
      return this.statuses;
    },
    parentChangeStatus(status) {
      this.$emit("changeStatus", status);
    },
    exportPdf() {
      // limit export
      if (this.totalOrders > 1000) {
        this.$toast.error(
          "Max limit of 1000 work-orders can be exported, Use filter to limit the numbers."
        );
      } else {
        this.setExporting();
        window.axios
          .put(
            `/api/orders/generate-orders-pdf`,
            { ...this.form, ...{ pdf: true } },
            { responseType: "blob" }
          )
          .then((response) => {
            this.setExportDone();
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            let filename = "work-orders.pdf";
            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch(() => {
            this.setExportDone();
          });
      }
    },
    getStatuses() {
      window.axios(`/api/orders/statuses`).then((r) => {
        this.statuses = r.data;
      });
    },
    resetFilter() {
      this.form = this.initForm();
      this.paginate();
    },
    simpleSearchClear() {
      this.form.name = "";
      this.paginate(this.form);
    },
    changeTableHeaders() {
      // Special table header manipulation, if date_type is set from search area, then make the first column dynamic based on the search criteria
      if (this.isAuthOperator) {
        const searchedDateType = this.date_types.find(
          (o) => o.field === this.form.date_type
        );
        const dateTypes = this.date_types.map((o) => o.field);

        if (this.form.date_type !== "" && this.form.date_type !== null) {
          // exclude all types
          this.headers = this.headers.filter(
            (o) => !dateTypes.includes(o.value)
          );
          // re-enter the selected type
          this.headers.unshift({
            text: searchedDateType.label,
            value: searchedDateType.field,
          });
        } else {
          // add created_at by default
          if (!this.headers.find((o) => o.value === "created_at")) {
            this.headers = headers;
          }
        }
      }
    },
    search() {
      this.options.page = 1;
      this.paginate(this.form);
    },
    initForm() {
      return {
        name: "",
        pet_last_name: "",
        disposition_id: "",
        urn_id: "",
        clinic_id: "",
        status: "",
        region_id: "",
        date_type: "",
        date_from: "",
        date_to: "",
      };
    },
    displayUrn(urns) {
      let urnSet = [];
      urns.forEach((urn) => {
        if (parseInt(urn.pivot.quantity) > 1) {
          urnSet.push(`${urn.name} (${urn.pivot.quantity}pcs)`);
        } else {
          urnSet.push(`${urn.name}`);
        }
      });
      return urnSet.join(", ");
    },
    showDriverConfirmationAlert(item, type) {
      this.$confirm(`You are about to ${type} this order, Continue?`, {
        color: "warning",
        title: "Confirm",
      }).then((res) => {
        if (res) {
          const status = type === "pickup" ? 2 : 6;
          window.axios
            .put(`/api/orders/driver-action/${item.id}`, { status })
            .then(() => {
              this.$toast.success(type + " date saved.");
              this.paginate(this.form);
            });
        }
      });
    },
    weightIn(item) {
      this.$refs.weightInDialog.show(item);
    },
    updateStatus(item, status) {
      if (status === item.status) {
        return false;
      }
      window.axios
        .put(`/api/orders/update-status/${item.id}`, { status })
        .then(() => {
          this.$toast.success("Status updated.");
          this.paginate(this.form);
        });
    },
    getStatusColor(status) {
      let row = window._.find(this.statuses, { id: status });
      if (row) {
        return row.color;
      }
      return "secondary";
    },
    getStatusName(status) {
      let row = window._.find(this.statuses, { id: status });
      if (row) {
        return row.title;
      }
      return "secondary";
    },
    postTotalWeight() {
      this.getTotalWeight({ ...this.form, ...{ get_total_weight: 1 } }).then(
        () => {
          const weight =
            this.total_weight == null || this.total_weight == ""
              ? 0
              : this.total_weight.toLocaleString("en-US");
          this.$toast.success(`Total weight: ${weight} pounds.`);
        }
      );
    },
    paginate(params = {}) {
      this.loading = true;
      const parameters = { ...this.options, ...params };
      this.getOrders(parameters).then((r) => {
        this.totalOrders = +r.data.total;
        this.options.itemsPerPage = +r.data.per_page;
        this.loading = false;
      });
    },
    edit(item, editable = true) {
      let allowEdit = true;
      if (this.isAuthClinicUser && item.status >= 2) {
        allowEdit = false;
        if (editable) {
          this.$toast.error(
            `This work order has been picked up and can no longer be edited.`
          );
          return false;
        }
      }
      this.$refs.createOrderDialog.viewing = !editable;
      this.$refs.createOrderDialog.editable = allowEdit;
      this.$refs.createOrderDialog.edit(item);
    },
    remove(item) {
      if (this.isAuthClinicUser && item.status >= 2) {
        this.$toast.error(
          `This work order has been picked up and can no longer be removed.`
        );
        return false;
      }
      this.$confirm("Do you really want to delete this order?", {
        color: "error",
        title: "Warning",
      }).then((res) => {
        if (res) {
          window.axios.delete(`/api/orders/${item.id}`).then(() => {
            this.paginate(this.form);
            this.$toast.success("Order deleted.");
          });
        }
      });
    },
  },
  filters: {
    formatDate(value) {
      if (value === null) {
        return;
      }
      return format(new Date(value.replace(/ /g, "T")), "MMM, dd, yyyy");
    },
  },
};
</script>