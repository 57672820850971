<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable width="500px">
      <v-card>
        <v-card-title>{{
          !showResetForm ? "Login" : "Password Reset"
        }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 250px; padding-top: 20px">
          <v-form>
            <v-container v-if="!showResetForm">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    :error-messages="
                      errors.get('username') || errors.get('email')
                    "
                    required
                    outlined
                    label="Username / Email"
                    v-model="login"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    :error-messages="errors.get('password')"
                    required
                    outlined
                    label="Password"
                    type="password"
                    v-model="password"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn
                plain
                class="pa-0 float-right"
                @click="showResetForm = true"
                >Forgot Password</v-btn
              >
            </v-container>
            <v-container v-else>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    :error-messages="errors.get('email')"
                    required
                    outlined
                    label="Email"
                    v-model="email"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn
                plain
                class="pa-0 float-right"
                @click="showResetForm = false"
                >Back To Login</v-btn
              >
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain large @click="dialog = false"> Cancel </v-btn>
          <v-btn
            v-if="!showResetForm"
            large
            color="teal lighten-1"
            class="white--text"
            depressed
            @click="signin"
            :disabled="isProcessing"
          >
            Login
          </v-btn>
          <v-btn
            v-else
            large
            color="teal lighten-1"
            class="white--text"
            depressed
            @click="forgotPassword"
            :disabled="isProcessing"
          >
            Send Password Reset Link
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import FormErrors from "./../../helpers/form-errors";
import { setToken } from "./../../helpers/token";
import { mapActions } from "vuex";

export default {
  data: () => ({
    errors: new FormErrors(),
    dialog: false,
    login: "",
    password: "",
    email: "",
    isProcessing: false,
    showResetForm: false,
  }),
  methods: {
    ...mapActions(["setAuthUser"]),
    forgotPassword() {
      const form = {
        email: this.email,
      };
      this.isProcessing = true;
      window.axios
        .post(`/api/forgot-password`, form)
        .then(() => {
          this.isProcessing = false;
          this.dialog = false;
          this.$toast.success(
            "Your password reset link has been sent. Please check your inbox and remember to check all folders and junk."
          );
        })
        .catch((e) => {
          this.errors.clear();
          this.isProcessing = false;
          if (e.response.status == 401) {
            if (e.response.data == "passwords.user") {
              this.$toast.error("The email is not registered to the system.");
            }
            if (e.response.data == "passwords.throttled") {
              this.$toast.error(
                "You have already requested password reset. Please check your email."
              );
            }
          }
          if (e.response.status == 422) {
            this.errors.record(e.response.data.errors);
          }
        });
    },
    signin() {
      const form = {
        login: this.login,
        password: this.password,
      };
      this.isProcessing = true;
      window.axios
        .post(`/api/login`, form)
        .then((r) => {
          this.isProcessing = false;
          this.dialog = false;
          setToken(r.data.access_token);
          this.errors.clear();
          this.setAuthUser().then(() => {
            this.$router.push({ name: "Orders" });
          });
        })
        .catch((e) => {
          this.errors.clear();
          this.isProcessing = false;
          if (e.response.status == 401) {
            this.$toast.error(e.response.data?.message);
          }
          if (e.response.status == 422) {
            this.errors.record(e.response.data.errors);
          }
        });
    },
  },
};
</script>

<style>
</style>
