<template>
  <v-fade-transition mode="out-in">
    <home />
  </v-fade-transition>
</template>

<script>
import Home from "./pages/public/Home.vue";
import { mapActions, mapState } from "vuex";
import { getToken } from "./helpers/token";

export default {
  components: {
    Home,
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.authUser.authenticated,
    }),
  },
  created() {
    if (getToken()) {
      this.setAuthUser();

      if (this.$route.name == "NotFound") {
        this.$router.push({ name: "NotFound" });
      }
    } else {
      if (!["Reset", "Login", "Home"].includes(this.$route.name)) {
        this.$router.push({ name: "Home" });
      }
    }
  },
  methods: {
    ...mapActions(["setAuthUser"]),
  },
  data() {
    return {};
  },
};
</script>