export default {
  state: {
    urns: [],
  },
  mutations: {
    setUrns(state, payload) {
      state.urns = payload.urns;
    },
  },
  actions: {
    getUrns: ({ commit }, params) => {
      return new Promise((resolve, reject) => {
        window.axios
          .post(`/api/urns/filter`, params)
          .then((r) => {
            commit({
              type: "setUrns",
              urns: r.data.data,
            });

            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
