export default {
  state: {
    clinics: [],
  },
  mutations: {
    setClinics(state, payload) {
      state.clinics = payload.clinics;
    },
  },
  actions: {
    getClinics: ({ commit }, params) => {
      return new Promise((resolve, reject) => {
        window.axios
          .post(`/api/clinics/filter`, params)
          .then((r) => {
            commit({
              type: "setClinics",
              clinics: r.data.data,
            });

            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
