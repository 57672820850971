<template>
  <v-row justify="center">
    <v-dialog
      hide-overlay
      scrollable
      v-model="modal"
      width="600px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>Activities</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 520px">
          <v-skeleton-loader
            v-if="skeleton"
            type="article, article"
          ></v-skeleton-loader>
          <v-timeline>
            <v-timeline-item
              v-for="(activity, i) in activities"
              :key="i"
              color="teal lighten-2"
              small
            >
              <template v-slot:opposite>
                <span :class="`headline font-weight-bold teal--text`">{{
                  activity.created_at | formatDate
                }}</span>
              </template>
              <div class="py-4">
                <div>
                  {{ activity.description }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="close" large text> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { format } from "date-fns";
export default {
  data() {
    return {
      modal: false,
      activities: [],
      skeleton: false,
    };
  },
  methods: {
    close() {
      this.modal = false;
      this.activities = [];
    },
  },
  filters: {
    formatDate(value) {
      return format(new Date(value), "yyyy-MM-dd");
    },
  },
};
</script>