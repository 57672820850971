<template>
  <v-row>
    <v-col cols="12" md="2" sm="12">
      <v-btn
        color="teal accent-4"
        dark
        depressed
        @click="create"
        v-if="canCreateWorkOrder"
        ><v-icon small>mdi-pencil</v-icon>&nbsp; Create</v-btn
      >
    </v-col>
    <v-col cols="12" md="10" sm="12">
      <v-btn
        class="float-right ml-2 white--text"
        color="teal accent-4"
        depressed
        :disabled="exporting"
        v-if="!isAuthDriver && !isAuthReceiver"
        @click="exportPdf"
      >
        <v-icon small>mdi-file-download-outline</v-icon>&nbsp; Export PDF</v-btn
      >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            color="teal accent-4"
            dark
            depressed
            @click="getTotalWeight"
            v-if="isAuthOperator"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon small>mdi-weight-pound</v-icon>&nbsp; Batch Weight</v-btn
          >
        </template>
        <span>Use Advanced Filter to get the specific data.</span>
      </v-tooltip>
      <v-btn
        class="float-right"
        color="blue-grey"
        dark
        depressed
        @click="filter"
      >
        <v-icon small>mdi-filter</v-icon>&nbsp;
        {{ advance_filter ? "Hide Filter" : "Advanced Filter" }}</v-btn
      >
      <v-btn
        class="float-right mr-2"
        color="warning"
        dark
        depressed
        :outlined="onhold_trigger"
        @click="onHold"
        v-if="
          !advance_filter &&
          ((!isAuthClinicUser && !isAuthReceiver) || isAuthAdmin)
        "
      >
        {{ onhold_trigger ? "Hide On Hold" : "Show On Hold" }}</v-btn
      >
    </v-col>
    <v-col cols="12">
      <v-card flat>
        <table-data ref="tableData" @changeStatus="changeStatus" />
      </v-card>
    </v-col>
    <create-order ref="createOrderDialog" @reload="paginate"></create-order>
  </v-row>
</template>

<script>
import CreateOrder from "../../../components/create-order.vue";
import TableData from "./Table.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      region_id: null,
      search_term: "",
      advance_filter: false,
      onhold_trigger: false,
    };
  },
  computed: {
    ...mapState({
      regionOptions: (state) => state.regions.regions,
      exporting: (state) => state.orders.exporting,
    }),
    ...mapGetters([
      "isAuthAdmin",
      "isAuthClinicUser",
      "isAuthOperator",
      "isAuthDriver",
      "isAuthReceiver",
    ]),
    canCreateWorkOrder() {
      return this.isAuthAdmin || this.isAuthClinicUser;
    },
  },
  components: {
    TableData,
    CreateOrder,
  },
  mounted() {
    this.getClinics();
    this.getUrns();
    this.getDispositions();
    this.getRegions();
  },
  methods: {
    ...mapActions(["getClinics", "getUrns", "getDispositions", "getRegions"]),
    filter() {
      this.$refs.tableData.filter = !this.$refs.tableData.filter;
      this.advance_filter = this.$refs.tableData.filter;
      this.$refs.tableData.hideOnHold = this.$refs.tableData.filter;
    },
    exportPdf() {
      this.$refs.tableData.exportPdf();
    },
    getTotalWeight() {
      this.$refs.tableData.postTotalWeight();
    },
    paginate() {
      this.$refs.tableData.paginate();
    },
    changeStatus(status) {
      this.onhold_trigger = status === "ON HOLD";
    },
    onHold() {
      this.$refs.tableData.hideOnHold = !this.$refs.tableData.hideOnHold;
      this.$refs.tableData.form.status = !this.onhold_trigger ? "ON HOLD" : "";
      this.$refs.tableData.paginate(this.$refs.tableData.form);
      this.onhold_trigger = !this.onhold_trigger;
    },
    create() {
      this.$refs.createOrderDialog.dialog = true;
    },
  },
};
</script>