<template>
  <div>
    <v-card flat class="mb-5" v-show="filter">
      <v-form>
        <v-row>
          <v-col cols="4" class="pb-0" xs="12">
            <v-text-field
              clearable
              dense
              outlined
              label="Username"
              v-model="form.username"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="pb-0" xs="12">
            <v-text-field
              clearable
              dense
              outlined
              label="Name"
              v-model="form.name"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="pb-0" xs="12">
            <v-text-field
              clearable
              dense
              outlined
              label="Email"
              v-model="form.email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="pb-0" xs="12">
            <v-autocomplete
              clearable
              hide-details
              dense
              outlined
              :items="roleOptions"
              item-text="name"
              item-value="id"
              label="Role"
              v-model="form.role"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3" class="pb-0" xs="12">
            <v-autocomplete
              clearable
              hide-details
              dense
              outlined
              :items="regionOptions"
              item-text="name"
              item-value="id"
              label="Region"
              v-model="form.region_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3" class="pb-0" xs="12">
            <v-autocomplete
              clearable
              dense
              outlined
              :items="clinicOptions"
              label="Clinic"
              item-text="name"
              item-value="id"
              v-model="form.clinic_id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3" class="pb-0" xs="12">
            <v-autocomplete
              clearable
              dense
              outlined
              :items="statuses"
              label="Status"
              item-text="title"
              item-value="title"
              v-model="form.status"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              class="mr-5"
              color="blue-grey darken-1"
              dark
              depressed
              @click="search"
              >Search</v-btn
            >
            <v-btn dark color="grey" depressed outlined @click="resetFilter"
              >Reset Filter</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-tabs v-model="current_tab" v-if="isAuthAdmin">
      <v-tab v-for="tab in tabs" :key="tab">
        {{ tab }}
      </v-tab>
    </v-tabs>

    <v-data-table
      :headers="dynamicHeaders"
      :items="users"
      :options.sync="options"
      :server-items-length="totalUsers"
      :footer-props="footerProps"
      :loading="loading"
      class="mt-5"
    >
      <template v-slot:item.username="{ item }">
        <v-btn text color="teal darken-2" @click="edit(item, false)">
          {{ item.username }}
        </v-btn>
      </template>
      <template v-slot:item.is_active="{ item }">
        <record-activator
          :item="item"
          table="users"
          @reload="paginate"
        ></record-activator>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="amber darken-2"
              v-bind="attrs"
              v-on="on"
              @click="edit(item)"
            >
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="red"
              v-bind="attrs"
              v-on="on"
              @click="remove(item)"
            >
              <v-icon>mdi-close-box-outline</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template v-slot:item.roles="{ item }">
        <template v-for="role in item.roles">
          <v-chip :key="role.id" v-text="role.name" class="ma-2"> </v-chip>
        </template>
      </template>
    </v-data-table>
    <create-user ref="createUserDialog" @reload="paginate"></create-user>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import CreateUser from "../../../components/create-user.vue";
import RecordActivator from "../../../components/record-activator.vue";
export default {
  components: { CreateUser, RecordActivator },
  data() {
    return {
      tabs: ["System Users", "Clinic Users"],
      current_tab: 0,
      headers: [
        { text: "", value: "is_active", width: "50" },
        { text: "Username", value: "username" },
        { text: "Email", value: "email" },
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Role", value: "roles", sortable: false },
        { text: "Clinic", value: "clinic_name" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      loading: true,
      footerProps: {
        itemsPerPageOptions: [25, 50, 100],
      },
      totalUsers: 0,
      options: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 100,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: [],
      },
      filter: false,
      form: {},
      statuses: ["Active", "Inactive"],
    };
  },
  watch: {
    options: {
      handler() {
        this.paginate(this.form);
      },
      deep: true,
    },
    current_tab: {
      handler() {
        this.paginate(this.form);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.isAuthClinicUser) {
      this.current_tab = 1;
    }
  },
  computed: {
    ...mapState({
      authUser: (state) => state.authUser.user,
      users: (state) => state.users.users,
      roleOptions: (state) => state.roles.roles,
      clinicOptions: (state) => state.clinics.clinics,
      regionOptions: (state) => state.regions.regions,
    }),
    ...mapGetters(["isAuthAdmin", "isAuthClinicUser"]),
    dynamicHeaders() {
      if (this.current_tab === 0) {
        return window._.filter(this.headers, (o) => {
          return o.text != "Clinic";
        });
      }
      return this.headers;
    },
  },
  methods: {
    ...mapActions(["getUsers"]),
    resetFilter() {
      this.form = this.initForm();
      this.paginate();
    },
    search() {
      this.paginate(this.form);
    },
    initForm() {
      return {
        name: "",
        username: "",
        email: "",
        is_active: "",
        clinic_id: "",
        region_id: "",
        role: "",
        status: "",
      };
    },
    paginate(params = {}) {
      this.loading = true;
      this.getUsers({
        ...this.options,
        ...params,
        ...{ current_tab: this.current_tab },
      }).then((r) => {
        this.totalUsers = +r.data.total;
        this.options.itemsPerPage = +r.data.per_page;
        this.loading = false;
      });
    },
    edit(item, editable = true) {
      this.$refs.createUserDialog.viewing = !editable;
      this.$refs.createUserDialog.edit(item.id);
    },
    remove(item) {
      // disallow self-destroy
      if (item.id === this.authUser.id) {
        this.$toast.error("You are currently logged In!");
        return false;
      }
      this.$confirm("Do you really want to delete this user?", {
        color: "error",
        title: "Warning",
      }).then((res) => {
        if (res) {
          window.axios.delete(`/api/users/${item.id}`).then(() => {
            this.paginate(this.form);
            this.$toast.success("User deleted.");
          });
        }
      });
    },
  },
};
</script>