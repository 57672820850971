<template>
  <v-row>
    <v-col cols="6">
      <v-btn color="teal accent-4" dark depressed @click="create"
        ><v-icon small>mdi-pencil</v-icon>&nbsp; Create</v-btn
      >
    </v-col>
    <v-col cols="6">
      <v-btn
        color="blue-grey"
        dark
        depressed
        class="float-right"
        @click="filter"
      >
        <v-icon small>mdi-filter</v-icon>&nbsp; {{ advance_filter ? 'Hide Filter' : 'Filter' }}</v-btn
      >
    </v-col>
    <v-col cols="12">
      <v-card flat>
        <table-data ref="tableData" />
      </v-card>
    </v-col>
    <create-region ref="createRegionDialog" @reload="paginate"></create-region>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CreateRegion from "../../../components/create-region.vue";
import TableData from "./Table.vue";
export default {
  components: {
    TableData,
    CreateRegion,
  },
  data() {
    return {
      advance_filter: false,
    }
  },
  computed: {
    ...mapGetters(["isAuthAdmin"]),
  },
  mounted() {
    if (!this.isAuthAdmin) {
      this.$router.push({ name: "Forbidden" });
    }
    this.getCountries();
  },
  methods: {
    ...mapActions(["getCountries"]),
    filter() {
      this.$refs.tableData.filter = !this.$refs.tableData.filter;
      this.advance_filter = this.$refs.tableData.filter;
    },
    paginate() {
      this.$refs.tableData.paginate();
    },
    create() {
      this.$refs.createRegionDialog.dialog = true;
    },
  },
};
</script>