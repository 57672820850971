<template>
  <v-navigation-drawer
    v-model="drawerVal"
    app
    :mini-variant="mini"
    color="grey lighten-3"
  >
    <v-sheet
      color="grey lighten-3"
      class="d-block text-center mx-auto mt-6 mb-6"
    >
      <span class="h1" v-if="!mini">&nbsp; Fetch</span>
      <span class="h1" v-else>&nbsp;</span>
    </v-sheet>

    <v-divider class="mx-3 my-5"></v-divider>

    <v-list>
      <template v-for="[icon, text, link] in dynamicLinks">
        <v-tooltip right :key="text" :disabled="!mini">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item :key="icon" :to="link" v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon>{{ icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>{{ text }}</span>
        </v-tooltip>
      </template>
      <v-list-item class="collapse-menu" @click="mini = !mini">
        <v-list-item-icon>
          <v-icon>mdi-chevron-{{ mini ? "right" : "left" }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Collapse</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters(["isAuthAdmin", "isAuthClinicUser", "getDrawer"]),
    dynamicLinks() {
      if (!this.isAuthAdmin) {
        if (this.isAuthClinicUser) {
          return [
            ["mdi-clipboard-file-outline", "Work Orders", "/orders"],
          ];
        }
        return [["mdi-clipboard-file-outline", "Work Orders", "/orders"]];
      }
      return this.links;
    },
    drawerVal: {
      get() {
        return this.getDrawer;
      },
      set(value) {
        this.setDrawer(value);
      },
    },
  },
  data() {
    return {
      mini: true,
      links: [
        ["mdi-clipboard-file-outline", "Work Orders", "/orders"],
        ["mdi-domain", "Clinics", "/clinics"],
        ["mdi-text-box-outline", "Urn Types", "/urn-types"],
        ["mdi-playlist-minus", "Dispositions", "/dispositions"],
        ["mdi-account-group", "User Managemennt", "/users"],
        ["mdi-map-marker-outline", "Regions", "/regions"],
        ["mdi-application-cog", "Site Settings", "/site-settings"],
      ],
    };
  },
  methods: {
    ...mapMutations(["openDrawer", "closeDrawer", "setDrawer"]),
  },
};
</script>