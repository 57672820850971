export default {
  state: {
    regions: [],
  },
  mutations: {
    setRegions(state, payload) {
      state.regions = payload.regions;
    },
  },
  actions: {
    getRegions: ({ commit }, params) => {
      return new Promise((resolve, reject) => {
        window.axios
          .post(`/api/regions/filter`, params)
          .then((r) => {
            commit({
              type: "setRegions",
              regions: r.data.data,
            });

            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
