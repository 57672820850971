export default {
  state: {
    authenticated: false,
    user: {},
  },
  mutations: {
    setAuthUser(state, payload) {
      state.authenticated = true;
      state.user = payload.user;
    },
    unsetAuthUser(state) {
      state.authenticated = false;
      state.user = {};
    },
  },
  getters: {
    isAuthAdmin: (state) => {
      if (state.user.roles != "") {
        return window._.map(state.user.roles, "name").includes("System Admin") || window._.map(state.user.roles, "name").includes("App Admin");
      }
      return false;
    },
    isAuthClinicUser: (state) => {
      if (state.user.roles != "") {
        return window._.map(state.user.roles, "name").includes("Clinic User");
      }
      return false;
    },
    isAuthReceiver: (state) => {
      if (state.user.roles != "") {
        return window._.map(state.user.roles, "name").includes("Receiver");
      }
      return false;
    },
    isAuthOperator: (state) => {
      if (state.user.roles != "") {
        return window._.map(state.user.roles, "name").includes("Operator");
      }
      return false;
    },
    isAuthDriver: (state) => {
      if (state.user.roles != "") {
        return window._.map(state.user.roles, "name").includes("Driver");
      }
      return false;
    },
  },
  actions: {
    setAuthUser: ({ commit }) => {
      return new Promise((resolve, reject) => {
        window.axios
          .get(`/api/current-user`)
          .then((r) => {
            commit({
              type: "setAuthUser",
              user: r.data,
            });

            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    logoutUser: ({ commit }) => {
      commit({
        type: "unsetAuthUser",
      });
    },
  },
};
