<template>
  <v-row>
    <v-col cols="6">
      <v-btn color="teal accent-4" dark depressed @click="create"
        ><v-icon small>mdi-pencil</v-icon>&nbsp; Create</v-btn
      >
    </v-col>
    <v-col cols="6">
      <v-btn color="blue-grey" dark depressed class="float-right" @click="filter">
        <v-icon small>mdi-filter</v-icon>&nbsp; {{ advance_filter ? 'Hide Filter' : 'Filter' }}</v-btn
      >
    </v-col>
    <v-col cols="12">
      <v-card flat>
        <table-data ref="tableData" />
      </v-card>
    </v-col>
    <create-disposition
      ref="createDispositionDialog"
      @reload="paginate"
    ></create-disposition>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import CreateDisposition from "../../../components/create-disposition.vue";
import TableData from "./Table.vue";
export default {
  components: {
    TableData,
    CreateDisposition,
  },
  data() {
    return {
      advance_filter: false,
    }
  },
  computed: {
    ...mapGetters(["isAuthAdmin"]),
  },
  mounted() {
    if (!this.isAuthAdmin) {
      this.$router.push({ name: "Forbidden" });
    }
  },
  methods: {
    filter() {
      this.$refs.tableData.filter = !this.$refs.tableData.filter;
      this.advance_filter = this.$refs.tableData.filter;
    },
    paginate() {
      this.$refs.tableData.paginate();
    },
    create() {
      this.$refs.createDispositionDialog.dialog = true;
    },
  },
};
</script>