<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      width="800px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>{{ title }} User</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 520px">
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    :disabled="viewing"
                    :error-messages="errors.get('first_name')"
                    required
                    label="First Name"
                    v-model="form.first_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    :disabled="viewing"
                    :error-messages="errors.get('last_name')"
                    required
                    label="Last Name"
                    v-model="form.last_name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    :disabled="viewing"
                    :error-messages="errors.get('username')"
                    required
                    label="Username"
                    v-model="form.username"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    :disabled="viewing"
                    :error-messages="errors.get('email')"
                    required
                    label="Email"
                    v-model="form.email"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="!viewing">
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    :disabled="viewing"
                    :placeholder="
                      action == 'Edit'
                        ? '(Leave blank if you dont want to change it)'
                        : ''
                    "
                    :error-messages="errors.get('password')"
                    required
                    type="password"
                    label="Password"
                    v-model="form.password"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    :disabled="viewing"
                    type="password"
                    label="Confirm Password"
                    v-model="form.password_confirmation"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="isAuthAdmin">
                <v-col cols="12" class="pb-0">
                  <v-autocomplete
                    :disabled="viewing"
                    :error-messages="errors.get('roles')"
                    required
                    multiple
                    :items="roleOptions"
                    item-text="name"
                    item-value="name"
                    label="Role"
                    v-model="form.roles"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                  v-if="form.roles.includes('Clinic User')"
                >
                  <v-autocomplete
                    :disabled="viewing"
                    :error-messages="errors.get('clinic_id')"
                    required
                    clearable
                    :items="clinicOptions"
                    item-text="name"
                    item-value="id"
                    label="Clinic"
                    v-model="form.clinic_id"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-autocomplete
                    :disabled="viewing"
                    :items="regionOptions"
                    item-text="name"
                    item-value="id"
                    label="Region"
                    v-model="form.region_id"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain @click="close" large> Cancel </v-btn>
          <v-btn
            v-if="viewing"
            color="amber darken-2"
            class="white--text"
            :disabled="updating"
            depressed
            @click="viewing = false"
            large
          >
            Edit
          </v-btn>
          <v-btn
            v-else
            color="teal lighten-1"
            class="white--text"
            :disabled="updating"
            depressed
            @click="save"
            large
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import FormErrors from "./../helpers/form-errors";
export default {
  data: () => ({
    form: {},
    errors: new FormErrors(),
    dialog: false,
    updating: false,
    action: "Create",
    viewing: false,
  }),
  computed: {
    ...mapState({
      roleOptions: (state) => state.roles.roles,
      clinicOptions: (state) => state.clinics.clinics,
      regionOptions: (state) => state.regions.regions,
      authUser: (state) => state.authUser.user,
    }),
    ...mapGetters(["isAuthAdmin", "isAuthClinicUser"]),
    title() {
      if (this.viewing) {
        return "Details: ";
      }
      return this.action;
    },
  },
  created() {
    this.form = this.initForm();
  },
  mounted() {
    if (this.roleOptions.length === 0) {
      this.getRoles();
    }
  },
  methods: {
    ...mapActions(["getRoles", "setAuthUser"]),
    save() {
      if (!this.form.roles.includes("Clinic User")) {
        this.form.clinic_id = null;
      }
      if (this.action == "Create") {
        this.store();
      } else {
        this.update();
      }
    },
    edit(id) {
      this.action = "Edit";
      this.dialog = true;
      this.updating = true;
      window.axios.get(`/api/users/${id}`).then((r) => {
        this.updating = false;
        this.form = {
          id: r.data.id,
          first_name: r.data.first_name,
          last_name: r.data.last_name,
          email: r.data.email,
          username: r.data.username,
          roles: window._.map(r.data.roles, "name"),
          clinic_id: r.data.clinic_id,
          region_id: r.data.region_id,
        };
      });
    },
    update() {
      this.updating = true;
      this.form.updated_by = this.authUser.id;
      window.axios
        .put(`/api/users/${this.form.id}`, this.form)
        .then(() => {
          this.updating = false;
          this.$toast.success("User updated.");

          // if updating currently logged in user, then reload the authUser state
          if (this.authUser.id == this.form.id) {
            this.setAuthUser().then(() => {
              // wait until update is done so the table will reload accordingly if roles has been updated
              this.$emit("reload");
            });
          } else {
            this.$emit("reload");
          }
          this.close();
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$toast.error("Please complete all required fields.");
            this.errors.record(e.response.data.errors);
          }
          this.updating = false;
        });
    },
    store() {
      this.updating = true;
      this.form.created_by = this.authUser.id;
      window.axios
        .post(`/api/users`, this.form)
        .then(() => {
          this.updating = false;
          this.$toast.success("User created.");
          this.close();
          this.$emit("reload");
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$toast.error("Please complete all required fields.");
            this.errors.record(e.response.data.errors);
          }
          this.updating = false;
        });
    },
    close() {
      this.errors.clear();
      this.dialog = false;
      this.form = this.initForm();
      this.action = "Create";
    },
    initForm() {
      return {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        region_id: "",
        roles: [],
        clinic_id: "",
        password: "",
        password_confirmation: "",
      };
    },
  },
};
</script>

<style>
</style>
