<template>
  <div>
    <v-card flat class="mb-5" v-show="filter">
      <v-form>
        <v-row>
          <v-col cols="6" class="pb-0" xs="12">
            <v-text-field
              clearable
              dense
              outlined
              label="Name"
              v-model="form.name"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pb-0" xs="12">
            <v-autocomplete
              clearable
              dense
              outlined
              :items="statuses"
              label="Status"
              item-text="title"
              item-value="title"
              v-model="form.status"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              class="mr-5"
              color="blue-grey darken-1"
              dark
              depressed
              @click="search"
              >Search</v-btn
            >
            <v-btn dark color="grey" depressed outlined @click="resetFilter"
              >Reset Filter</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="dispositions"
      :options.sync="options"
      :server-items-length="totalDispositions"
      :footer-props="footerProps"
      :loading="loading"
    >
      <template v-slot:item.is_active="{ item }">
        <record-activator
          :item="item"
          table="dispositions"
          @reload="paginate"
        ></record-activator>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="amber darken-2"
              v-bind="attrs"
              v-on="on"
              @click="edit(item)"
            >
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="red"
              v-bind="attrs"
              v-on="on"
              @click="remove(item)"
            >
              <v-icon>mdi-close-box-outline</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <create-disposition
      ref="createDispositionDialog"
      @reload="paginate"
    ></create-disposition>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import createDisposition from "../../../components/create-disposition.vue";
import RecordActivator from "../../../components/record-activator.vue";
export default {
  components: { createDisposition, RecordActivator },
  data() {
    return {
      headers: [
        { text: "", value: "is_active", width: "50" },
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Sort Order", value: "sort_order" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      loading: true,
      footerProps: {
        itemsPerPageOptions: [25, 50, 100],
      },
      totalDispositions: 0,
      options: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 100,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: [],
      },
      filter: false,
      form: {},
      statuses: ["Active", "Inactive"],
    };
  },
  watch: {
    options: {
      handler() {
        this.paginate(this.form);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      dispositions: (state) => state.dispositions.dispositions,
    }),
  },
  methods: {
    ...mapActions(["getDispositions"]),
    resetFilter() {
      this.form = this.initForm();
      this.paginate();
    },
    search() {
      this.paginate(this.form);
    },
    initForm() {
      return {
        name: "",
        status: "",
      };
    },
    paginate(params = {}) {
      this.loading = true;
      this.getDispositions({ ...this.options, ...params }).then((r) => {
        this.totalDispositions = +r.data.total;
        this.options.itemsPerPage = +r.data.per_page;
        this.loading = false;
      });
    },
    edit(item) {
      this.$refs.createDispositionDialog.edit(item);
    },
    remove(item) {
      this.$confirm("Do you really want to delete this disposition?", {
        color: "error",
        title: "Warning",
      }).then((res) => {
        if (res) {
          window.axios.delete(`/api/dispositions/${item.id}`).then(() => {
            this.paginate();
            this.$toast.success("Disposition deleted.");
          });
        }
      });
    },
  },
};
</script>