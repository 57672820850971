<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      width="120vh"
      scrollable
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>{{ title }} Work Order</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 520px">
          <v-form>
            <v-container class="mt-1">
              <v-row v-if="viewing">
                <v-col cols="4" class="pb-0">
                  <v-text-field
                    label="Status"
                    v-model="form.status_name"
                    :disabled="viewing"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="pb-0">
                  <v-text-field
                    label="PickedUp Date"
                    v-model="form.pickedup_at"
                    :disabled="viewing"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="pb-0">
                  <v-text-field
                    label="DroppedOff Date"
                    v-model="form.droppedoff_at"
                    :disabled="viewing"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="pb-0">
                  <v-text-field
                    :error-messages="errors.get('name')"
                    required
                    hide-details="auto"
                    v-model="form.name"
                    :disabled="viewing"
                  >
                    <template v-slot:label>
                      Pet Name <span style="color: #ef5350">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="3" class="pb-0">
                  <v-text-field
                    required
                    hide-details="auto"
                    label="Last Name"
                    v-model="form.pet_last_name"
                    :disabled="viewing"
                    :error-messages="errors.get('pet_last_name')"
                  >
                    <template v-slot:label>
                      Last Name <span style="color: #ef5350">*</span>
                    </template></v-text-field
                  >
                </v-col>
                <v-col cols="3" class="pb-0">
                  <v-text-field
                    required
                    hide-details="auto"
                    label="Breed"
                    v-model="form.breed"
                    :disabled="viewing"
                    :error-messages="errors.get('breed')"
                  >
                    <template v-slot:label>
                      Breed <span style="color: #ef5350">*</span>
                    </template></v-text-field
                  >
                </v-col>
                <v-col cols="3" class="pb-0">
                  <v-text-field
                    required
                    hide-details="auto"
                    label="Colour"
                    v-model="form.color"
                    :disabled="viewing"
                    :error-messages="errors.get('color')"
                  >
                    <template v-slot:label>
                      Color <span style="color: #ef5350">*</span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="pb-0">
                  <v-autocomplete
                    required
                    hide-details="auto"
                    :items="['Dog', 'Cat', 'Other']"
                    label="Pet Type"
                    v-model="form.pet_type"
                    :disabled="viewing"
                    :error-messages="errors.get('pet_type')"
                  >
                    <template v-slot:label>
                      Pet Type <span style="color: #ef5350">*</span>
                    </template></v-autocomplete
                  >
                </v-col>
                <v-col cols="3" class="pb-0">
                  <v-menu
                    ref="menu_date_of_passing"
                    v-model="menu_date_of_passing"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        clearable
                        readonly
                        v-model="form.passed_at"
                        label="Date of Passing"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="viewing"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      scrollable
                      v-model="form.passed_at"
                      @input="menu_date_of_passing = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="3" class="pb-0">
                  <v-text-field
                    label="Age"
                    hide-details="auto"
                    v-model="form.age"
                    :disabled="viewing"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" class="pb-0">
                  <v-autocomplete
                    required
                    hide-details="auto"
                    :items="['Male', 'Female']"
                    label="Gender"
                    v-model="form.gender"
                    :disabled="viewing"
                    :error-messages="errors.get('gender')"
                  >
                    <template v-slot:label>
                      Gender <span style="color: #ef5350">*</span>
                    </template></v-autocomplete
                  >
                </v-col>
              </v-row>
              <v-row v-if="isAuthAdmin || isAuthClinicUser || isAuthReceiver">
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    type="number"
                    label="Weight (lbs)"
                    hide-details="auto"
                    v-model="form.weight"
                    :disabled="viewing"
                    required
                    :error-messages="errors.get('weight')"
                  >
                    <template v-slot:label>
                      Weight (lbs)<span style="color: #ef5350">*</span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-autocomplete
                    :error-messages="errors.get('disposition_id')"
                    required
                    hide-details="auto"
                    :items="dispositionOptions"
                    label="Disposition"
                    item-text="name"
                    item-value="id"
                    v-model="form.disposition_id"
                    :disabled="viewing"
                  >
                    <template v-slot:label>
                      Disposition <span style="color: #ef5350">*</span>
                    </template></v-autocomplete
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-autocomplete
                    chips
                    deletable-chips
                    multiple
                    :error-messages="errors.get('urn_id')"
                    required
                    :items="urnOptions"
                    hide-details="auto"
                    label="URN Type"
                    item-text="name"
                    item-value="id"
                    v-model="form.urn_id"
                    :disabled="viewing"
                    @change="selectUrn"
                  >
                    <template v-slot:label>
                      URN Type
                      <span style="color: #ef5350">*</span>
                    </template></v-autocomplete
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-for="urn in selectedUrns"
                  :key="urn.id"
                  class="d-flex child-flex"
                  cols="4"
                >
                  <v-card outlined>
                    <v-card-text v-if="urn.name !== 'none'">
                      <v-row no-gutters>
                        <v-col cols="8">
                          <v-subheader class="pl-0"
                            ><strong>Quantity: </strong></v-subheader
                          >
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            type="number"
                            label=""
                            hide-details="auto"
                            v-model="urn.pivot.quantity"
                            :disabled="viewing"
                            :min="1"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-img
                      style="cursor: pointer"
                      @click="viewImage(urn)"
                      :src="`${publicUrl}/images/urns/${urn.image}`"
                      aspect-ratio="1"
                      class="grey lighten-2"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                        </v-row>
                      </template>
                    </v-img>
                    <v-card-title>{{ urn.name }}</v-card-title>

                    <v-card-text>
                      <div class="text-subtitle-1">$ {{ urn.price }}</div>

                      <div class="pt-5">
                        {{ urn.description }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row v-if="isAuthAdmin || isAuthReceiver || isAuthOperator">
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    label="Invoice Number"
                    hide-details="auto"
                    v-model="form.invoice_number"
                    :disabled="viewing"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pb-0" v-if="!isAuthClinicUser">
                  <v-autocomplete
                    :items="clinicOptions"
                    label="Clinic"
                    hide-details="auto"
                    item-text="name"
                    item-value="id"
                    v-model="form.clinic_id"
                    :disabled="viewing"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                  <v-checkbox
                    v-model="form.standard_inscription"
                    label="Standard Inscription"
                    hide-details="auto"
                    :disabled="viewing"
                    @change="standardInscription"
                  ></v-checkbox>
                  <p v-if="form.standard_inscription">
                    Forever faithful, Forever at peace
                  </p>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-textarea
                    hide-details="auto"
                    clearable
                    auto-grow
                    rows="1"
                    row-height="15"
                    label="Special Notes/Name Plate instructions"
                    v-model="form.special_note"
                    :disabled="viewing"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    hide-details="auto"
                    label="Employee Name"
                    v-model="form.employee"
                    :disabled="viewing"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container class="mt-4">
              <p class="mb-1"><strong>Owner Details:</strong></p>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="4" class="pb-0">
                  <v-text-field
                    required
                    hide-details="auto"
                    label="First Name"
                    v-model="form.owner.first_name"
                    :disabled="viewing"
                    :error-messages="errors.get('owner.first_name')"
                  >
                    <template v-slot:label>
                      First Name <span style="color: #ef5350">*</span>
                    </template></v-text-field
                  >
                </v-col>
                <v-col cols="4" class="pb-0">
                  <v-text-field
                    required
                    hide-details="auto"
                    label="Last Name"
                    v-model="form.owner.last_name"
                    :disabled="viewing"
                    :error-messages="errors.get('owner.last_name')"
                    ><template v-slot:label>
                      Last Name <span style="color: #ef5350">*</span>
                    </template></v-text-field
                  >
                </v-col>
                <v-col cols="4" class="pb-0">
                  <v-text-field
                    hide-details="auto"
                    label="Phone"
                    v-model="form.owner.phone"
                    :disabled="viewing"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="pb-0">
                  <v-text-field
                    hide-details="auto"
                    label="Address"
                    v-model="form.owner.address"
                    :disabled="viewing"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="pb-0">
                  <v-text-field
                    hide-details="auto"
                    label="City"
                    v-model="form.owner.city"
                    :disabled="viewing"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="pb-0">
                  <v-text-field
                    hide-details="auto"
                    label="Postal Code"
                    v-model="form.owner.postal_code"
                    :disabled="viewing"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <v-container class="mt-4">
              <p class="mb-1"><strong>Agent Details:</strong></p>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="4" class="pb-0">
                  <v-text-field
                    hide-details="auto"
                    label="First Name"
                    v-model="form.agent.first_name"
                    :disabled="viewing"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="pb-0">
                  <v-text-field
                    hide-details="auto"
                    label="Last Name"
                    v-model="form.agent.last_name"
                    :disabled="viewing"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="pb-0">
                  <v-text-field
                    hide-details="auto"
                    label="Phone"
                    v-model="form.agent.phone"
                    :disabled="viewing"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="pb-0">
                  <v-text-field
                    hide-details="auto"
                    label="Address"
                    v-model="form.agent.address"
                    :disabled="viewing"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="pb-0">
                  <v-text-field
                    hide-details="auto"
                    label="City"
                    v-model="form.agent.city"
                    :disabled="viewing"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="pb-0">
                  <v-text-field
                    hide-details="auto"
                    label="Postal Code"
                    v-model="form.agent.postal_code"
                    :disabled="viewing"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="viewing"
            color="blue-grey darken-1"
            class="white--text"
            depressed
            @click="viewActivities"
            large
            :disabled="updating"
          >
            <v-icon>mdi-timeline-text-outline</v-icon>&nbsp; View Activities
          </v-btn>
          <v-btn
            v-if="action != 'Create'"
            color="grey darken-3"
            class="white--text"
            depressed
            @click="generateForm"
            large
            :disabled="updating"
          >
            <v-icon>mdi-file-document-outline</v-icon>&nbsp; Generate Form
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn plain @click="close" class="mr-2" large> Cancel </v-btn>
          <template v-if="viewing">
            <v-btn
              color="amber darken-2"
              class="white--text"
              depressed
              @click="viewing = false"
              :disabled="updating"
              large
              v-if="editable"
            >
              Edit
            </v-btn>
          </template>
          <template v-else>
            <template v-if="action == 'Create'">
              <v-btn-toggle>
                <v-btn
                  color="teal"
                  class="white--text"
                  dark
                  @click="save(1, true)"
                >
                  Save & Generate Form
                </v-btn>

                <v-menu offset-y top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="teal darken-2" v-bind="attrs" v-on="on">
                      <v-icon class="white--text">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-if="isAuthClinicUser || isAuthAdmin"
                      :disabled="updating"
                      @click="save(0)"
                    >
                      <v-list-item-title>Save On Hold</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      v-if="isAuthClinicUser || isAuthAdmin"
                      :disabled="updating"
                      @click="save(1)"
                    >
                      <v-list-item-title
                        >Save Ready for Pickup</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-btn-toggle>
            </template>

            <template v-else>
              <v-btn
                color="teal"
                class="white--text"
                depressed
                @click="save('', true)"
                large
                outlined
                :disabled="updating"
              >
                Save & Generate Form
              </v-btn>
              <v-btn
                color="teal lighten-1"
                class="white--text"
                depressed
                @click="save('')"
                large
                :disabled="updating"
              >
                Save
              </v-btn>
            </template>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <work-order-activities ref="activityDialog"></work-order-activities>
    <urn-image-viewer ref="urnImageViewerDialog"></urn-image-viewer>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FormErrors from "./../helpers/form-errors";
import UrnImageViewer from "./urn-image-viewer.vue";
import WorkOrderActivities from "./work-order-activities.vue";
export default {
  components: {
    WorkOrderActivities,
    UrnImageViewer,
  },
  data: () => ({
    menu_date_of_passing: false,
    form: {},
    errors: new FormErrors(),
    dialog: false,
    updating: false,
    action: "Create",
    viewing: false,
    selectedUrns: [],
    editable: true,
    isGenerateForm: false,
    formCopy: {},
  }),
  created() {
    this.form = this.initForm();
  },
  computed: {
    ...mapState({
      dispositions: (state) => state.dispositions.dispositions,
      urns: (state) => state.urns.urns,
      clinics: (state) => state.clinics.clinics,
      regions: (state) => state.regions.regions,
    }),
    ...mapGetters([
      "isAuthAdmin",
      "isAuthClinicUser",
      "isAuthOperator",
      "isAuthDriver",
      "isAuthReceiver",
    ]),
    dispositionName() {
      const disposition = this.dispositions.find(
        (o) => o.id === this.form.disposition_id
      );
      return disposition?.name;
    },
    publicUrl() {
      return process.env.VUE_APP_API_URL;
    },
    title() {
      if (this.viewing) {
        return "Details: ";
      }
      return this.action;
    },
    urnOptions() {
      if (this.form.id) {
        // check if the selected urn is already deactivated then still display it
        // check from copy because original form can be updated and once remove from option it will never show up again
        if (this.formCopy.urn_id.length > 0) {
          return this.urns.filter(
            (o) => o.is_active || this.formCopy.urn_id.includes(o.id)
          );
        }
      }
      return this.urns.filter((o) => o.is_active);
    },
    dispositionOptions() {
      if (this.form.id) {
        if (this.formCopy.disposition_id !== "") {
          return this.dispositions.filter(
            (o) => o.is_active || this.formCopy.disposition_id === o.id
          );
        }
      }
      return this.dispositions.filter((o) => o.is_active);
    },
    clinicOptions() {
      if (this.form.id) {
        if (this.formCopy.clinic_id !== "") {
          return this.clinics.filter(
            (o) => o.is_active || this.formCopy.clinic_id === o.id
          );
        }
      }
      return this.clinics.filter((o) => o.is_active);
    },
  },
  methods: {
    standardInscription(val) {
      console.log(val, this.form.special_note);
      if (
        this.form.special_note === null ||
        this.form.special_note === "" ||
        this.form.special_note === "No Standard Inscription"
      ) {
        this.form.special_note = !val ? "No Standard Inscription" : "";
      }
    },
    viewImage(urn) {
      this.$refs.urnImageViewerDialog.image = urn.image;
      this.$refs.urnImageViewerDialog.dialog = true;
    },
    selectUrn(selected) {
      const urns = window._.filter(this.urns, (o) => {
        return selected.includes(o.id);
      });
      const existing = this.selectedUrns.map((o) => o.id);

      // get removed
      const removedUrns = existing.filter((o) => !selected.includes(o));
      // get new
      const newUrns = urns
        .filter((o) => !existing.includes(o.id))
        .map((o) => {
          const container = o;
          container.pivot = {
            quantity: 1,
          };
          return o;
        });

      this.selectedUrns = this.selectedUrns
        .concat(newUrns)
        .filter((o) => !removedUrns.includes(o.id));
    },
    generateForm() {
      window.axios
        .post(
          `/api/orders/generate-form`,
          { is_blank_form: false, id: this.form.id },
          { responseType: "blob" }
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let filename = "authorization-form.pdf";
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    viewActivities() {
      this.$refs.activityDialog.modal = true;
      this.$refs.activityDialog.skeleton = true;
      window.axios.get(`/api/orders/${this.form.id}/activities`).then((r) => {
        this.$refs.activityDialog.activities = r.data;
        this.$refs.activityDialog.skeleton = false;
      });
    },
    save(status = "", generateForm = false) {
      this.form.status = status != "" ? status : this.form.status;
      this.isGenerateForm = generateForm;
      if (this.action == "Create") {
        this.store();
      } else {
        this.update();
      }
    },
    edit(item) {
      this.action = "Edit";
      this.dialog = true;
      this.updating = true;
      window.axios.get(`/api/orders/${item.id}`).then((r) => {
        const owner = r.data.owner;
        const agent = r.data.agent;
        this.updating = false;
        this.selectedUrns = r.data.urns;
        this.form = {
          id: r.data.id,
          name: r.data.name,
          pet_last_name: r.data.pet_last_name,
          breed: r.data.breed,
          color: r.data.color,
          pet_type: r.data.pet_type,
          passed_at: r.data.passed_at,
          age: r.data.age,
          gender: r.data.gender,
          disposition_id: r.data.disposition_id,
          urn_id: window._.map(r.data.urns, "id"),
          clinic_id: r.data.clinic_id,
          standard_inscription: r.data.standard_inscription,
          weight: r.data.weight,
          invoice_number: r.data.invoice_number,
          special_note: r.data.special_note,
          employee: r.data.employee,
          status: r.data.status,
          status_name: r.data.status_name,
          pickedup_at: r.data.pickedup_at,
          droppedoff_at: r.data.droppedoff_at,
          owner: {
            first_name: owner?.first_name,
            last_name: owner?.last_name,
            phone: owner?.phone,
            city: owner?.city,
            region_id: owner?.region_id,
            postal_code: owner?.postal_code,
            address: owner?.address,
          },
          agent: {
            first_name: agent?.first_name,
            last_name: agent?.last_name,
            phone: agent?.phone,
            city: agent?.city,
            region_id: agent?.region_id,
            postal_code: agent?.postal_code,
            address: agent?.address,
          },
        };
        this.formCopy = JSON.parse(JSON.stringify(this.form));
      });
    },
    update() {
      this.updating = true;
      window.axios
        .put(`/api/orders/${this.form.id}`, {
          ...this.form,
          ...{ selectedUrns: this.selectedUrns },
        })
        .then(() => {
          if (this.isGenerateForm) {
            this.generateForm();
          }
          this.close();
          this.updating = false;
          this.$toast.success("Work order updated.");
          this.$emit("reload");
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$toast.error("Please complete all required fields.");
            this.errors.record(e.response.data.errors);
          }
          this.updating = false;
        });
    },
    store() {
      this.updating = true;
      window.axios
        .post(`/api/orders`, {
          ...this.form,
          ...{ selectedUrns: this.selectedUrns },
        })
        .then((response) => {
          if (this.isGenerateForm) {
            this.form.id = response.data.id;
            this.generateForm();
          }
          this.close();
          this.updating = false;
          this.$toast.success("Work order created.");

          this.$emit("reload");
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$toast.error("Please complete all required fields.");
            this.errors.record(e.response.data.errors);
          }
          this.updating = false;
        });
    },
    close() {
      this.errors.clear();
      this.dialog = false;
      this.form = this.initForm();
      this.formCopy = {};
      this.action = "Create";
    },
    initForm() {
      return {
        id: null,
        passed_at: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        age: "",
        breed: "",
        gender: "",
        color: "",
        pet_type: "",
        name: "",
        pet_last_name: "",
        disposition_id: "",
        weight: null,
        invoice_number: null,
        urn_id: "",
        clinic_id: "",
        standard_inscription: 1,
        special_note: "",
        employee: "",
        status: 0, // ON HOLD
        status_name: "",
        owner: {
          first_name: "",
          last_name: "",
          phone: "",
          city: "",
          region_id: "",
          postal_code: "",
          address: "",
        },
        agent: {
          first_name: "",
          last_name: "",
          phone: "",
          city: "",
          region_id: "",
          postal_code: "",
          address: "",
        },
      };
    },
  },
};
</script>