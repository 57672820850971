export default {
  state: {
    informatives: [],
    settings: [],
  },
  mutations: {
    setInformatives(state, payload) {
      state.informatives = payload.informatives;
    },
    setSettings(state, payload) {
      state.settings = payload.settings;
    },
  },
  getters: {
    getSettingValue: (state) => (key) => {
      const row = window._.find(state.settings, (o) => {
        return o.key == key;
      });
      if (row) {
        return row.value;
      }
      return "";
    },
  },
  actions: {
    getInformatives: ({ commit }, params) => {
      return new Promise((resolve, reject) => {
        window.axios
          .post(`/api/informatives/filter`, params)
          .then((r) => {
            commit({
              type: "setInformatives",
              informatives: r.data.data,
            });

            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getSettings: ({ commit }) => {
      return new Promise((resolve, reject) => {
        window.axios
          .get(`/api/informatives/site-settings`)
          .then((r) => {
            commit({
              type: "setSettings",
              settings: r.data,
            });

            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
