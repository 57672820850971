<template>
  <div>
    <v-skeleton-loader
      v-if="skeleton"
      type="table-heading, list-item-two-line, list-item-two-line"
    ></v-skeleton-loader>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Value</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(setting, i) in settings" :key="i">
            <td>{{ setting.key }}</td>
            <td>
              <v-row>
                <v-col cols="6">
                  <template v-if="setting.editing">
                    <v-text-field
                      v-model="setting.value"
                      @keyup.enter="save(setting)"
                    ></v-text-field>
                  </template>
                  <template v-else>
                    <span>{{ setting.value }}</span>
                  </template>
                </v-col>
              </v-row>
            </td>
            <td>
              <template v-if="!setting.editing">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="amber darken-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="edit(setting)"
                    >
                      <v-icon>mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </template>
              <template v-else>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="teal lighten-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="save(setting)"
                    >
                      <v-icon>mdi-checkbox-marked-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Save</span>
                </v-tooltip>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  mounted() {
    this.fetchSettings();
  },
  data() {
    return {
      settings: [],
      skeleton: false,
    };
  },
  methods: {
    ...mapActions(["getSettings"]),
    edit(item) {
      item.editing = true;
    },
    save(item) {
      item.editing = false;
      window.axios
        .put(`/api/informatives/site-settings`, item)
        .then(() => {
          this.$toast.success("Value updated.");
        })
        .catch(() => {
          this.$toast.error("Value failed.");
          this.fetchSettings();
        });
    },
    fetchSettings() {
      this.skeleton = true;
      this.getSettings().then((r) => {
        this.skeleton = false;
        this.settings = JSON.parse(JSON.stringify(r.data));
      });
    },
  },
};
</script>