<template>
  <v-app-bar
    app
    flat
    shrink-on-scroll
    color="grey lighten-3"
    prominent
    height="73px"
  >
    <v-toolbar-title class="grey--text text--darken-3">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" text @click="triggerDrawer">
            <v-icon color="teal lighten-3" size="36">{{
              $route.meta.page_icon
            }}</v-icon>
            <v-icon size="15" color="grey" style="margin-top: 15px"
              >mdi-menu</v-icon
            >
          </v-btn>
        </template>
        <span>Toggle Sidebar</span> </v-tooltip
      >{{ $route.meta.page_title }}</v-toolbar-title
    >

    <v-spacer></v-spacer>

    <div class="avatar-btn-menu">
      <span>{{ user.first_name }}</span>
      <avatar-menu @account="account"></avatar-menu>
      <create-user ref="createUserDialog"></create-user>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import AvatarMenu from "../AvatarMenu.vue";
import CreateUser from "../create-user.vue";
export default {
  components: { AvatarMenu, CreateUser },
  computed: {
    ...mapState({
      user: (state) => state.authUser.user,
      drawer: (state) => state.sidebar.drawer,
    }),
  },
  methods: {
    ...mapMutations(["closeDrawer", "openDrawer"]),
    account() {
      this.$refs.createUserDialog.edit(this.user.id);
    },
    triggerDrawer() {
      if (this.drawer) {
        this.closeDrawer();
      } else {
        this.openDrawer();
      }
    },
  },
};
</script>