export default {
  state: {
    roles: [],
  },
  mutations: {
    setRoles(state, payload) {
      state.roles = payload.roles;
    },
  },
  actions: {
    getRoles: ({ commit }) => {
      return new Promise((resolve, reject) => {
        window.axios
          .get(`/api/roles`)
          .then((r) => {
            commit({
              type: "setRoles",
              roles: r.data,
            });

            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
