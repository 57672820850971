<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>{{ action }} Disposition</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    :error-messages="errors.get('name')"
                    required
                    label="Name"
                    v-model="form.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-textarea
                    clearable
                    auto-grow
                    rows="1"
                    row-height="15"
                    label="Description"
                    v-model="form.description"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    label="Sort Order"
                    v-model="form.sort_order"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain @click="close" large> Cancel </v-btn>
          <v-btn
            color="teal lighten-1"
            class="white--text"
            :disabled="updating"
            depressed
            @click="save"
            large
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import FormErrors from "./../helpers/form-errors";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      authUser: (state) => state.authUser.user,
    }),
  },
  data: () => ({
    form: {},
    errors: new FormErrors(),
    dialog: false,
    updating: false,
    action: "Create",
  }),
  created() {
    this.form = this.initForm();
  },
  methods: {
    save() {
      if (this.action == "Create") {
        this.store();
      } else {
        this.update();
      }
    },
    edit(item) {
      this.action = "Edit";
      this.dialog = true;
      this.updating = true;
      window.axios.get(`/api/dispositions/${item.id}`).then((r) => {
        this.updating = false;
        this.form = {
          id: r.data.id,
          name: r.data.name,
          description: r.data.description,
          sort_order: r.data.sort_order,
        };
      });
    },
    update() {
      this.updating = true;
      this.form.updated_by = this.authUser.id;
      window.axios
        .put(`/api/dispositions/${this.form.id}`, this.form)
        .then(() => {
          this.updating = false;
          this.$toast.success("Disposition updated.");
          this.close();
          this.$emit("reload");
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$toast.error("Please complete all required fields.");
            this.errors.record(e.response.data.errors);
          }
          this.updating = false;
        });
    },
    store() {
      this.updating = true;
      this.form.created_by = this.authUser.id;
      window.axios
        .post(`/api/dispositions`, this.form)
        .then(() => {
          this.updating = false;
          this.$toast.success("Disposition created.");
          this.close();
          this.$emit("reload");
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$toast.error("Please complete all required fields.");
            this.errors.record(e.response.data.errors);
          }
          this.updating = false;
        });
    },
    close() {
      this.errors.clear();
      this.dialog = false;
      this.form = this.initForm();
      this.action = "Create";
    },
    initForm() {
      return {
        id: null,
        name: "",
        description: "",
        sort_order: null,
      };
    },
  },
};
</script>