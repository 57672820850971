export default {
  state: {
    orders: [],
    total_weight: 0,
    exporting: false,
  },
  mutations: {
    setOrders(state, payload) {
      state.orders = payload.orders;
    },
    setExportDone(state) {
      state.exporting = false;
    },
    setExporting(state) {
      state.exporting = true;
    },
    setTotalWeight(state, payload) {
      state.total_weight = payload.total_weight;
    },
  },
  actions: {
    getOrders: ({ commit }, params) => {
      return new Promise((resolve, reject) => {
        window.axios
          .post(`/api/orders/filter`, params)
          .then((r) => {
            commit({
              type: "setOrders",
              orders: r.data.data,
            });

            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getTotalWeight: ({ commit }, params) => {
      return new Promise((resolve, reject) => {
        window.axios
          .post(`/api/orders/total-weight`, params)
          .then((r) => {
            commit({
              type: "setTotalWeight",
              total_weight: r.data.total_weight,
            });

            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
