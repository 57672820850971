<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>URN image</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px">
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-img
                    :src="`${publicUrl}/images/urns/${image}`"
                    class="grey lighten-2"
                  >
                  </v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain @click="dialog = false" large> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      image: undefined,
    };
  },
  computed: {
    ...mapState({
      authUser: (state) => state.authUser.user,
    }),
    publicUrl() {
      return process.env.VUE_APP_API_URL;
    },
  },
};
</script>