<template>
  <v-row>
    <v-col cols="12">
      <v-card flat>
        <v-card-title>Site Settings</v-card-title>
        <settings></settings>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card flat>
        <v-card-title
          >Informatives
          <v-btn color="teal accent-4" class="ml-2" dark depressed @click="create"
            ><v-icon small>mdi-pencil</v-icon>&nbsp; Create</v-btn
          ></v-card-title
        >

        <table-data ref="tableData" />
      </v-card>
    </v-col>
    <create-informative
      ref="createInformativeDialog"
      @reload="paginate"
    ></create-informative>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import CreateInformative from "../../../components/create-informative.vue";
import Settings from "./Settings.vue";
import TableData from "./Table.vue";
export default {
  components: {
    TableData,
    CreateInformative,
    Settings,
  },
  computed: {
    ...mapGetters(["isAuthAdmin"]),
  },
  mounted() {
    if (!this.isAuthAdmin) {
      this.$router.push({ name: "Forbidden" });
    }
  },
  methods: {
    filter() {
      this.$refs.tableData.filter = !this.$refs.tableData.filter;
    },
    paginate() {
      this.$refs.tableData.paginate();
    },
    create() {
      this.$refs.createInformativeDialog.dialog = true;
    },
  },
};
</script>