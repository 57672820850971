<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-avatar color="teal lighten-1" class="white--text" size="36">{{
          user.initials
        }}</v-avatar>
      </v-btn>
    </template>
    <v-list>
      <v-list-item link v-if="$route.name == 'Home'" to="/orders">
        <v-list-item-title>Go to Dashboard</v-list-item-title>
      </v-list-item>
      <v-list-item link v-if="$route.name !== 'Home'" to="/">
        <v-list-item-title>Go to Homepage</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="account" v-if="$route.name != 'Home'">
        <v-list-item-title>Account Settings</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item link @click="logout">
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { deleteToken } from "./../helpers/token";

export default {
  computed: {
    ...mapState({
      user: (state) => state.authUser.user,
    }),
  },
  methods: {
    ...mapActions(["logoutUser"]),
    account() {
      this.$emit("account");
    },
    logout() {
      deleteToken();
      this.logoutUser();
      this.$router.push({ path: "/" });
    },
  },
};
</script>