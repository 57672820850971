<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable width="500px">
      <v-card>
        <v-card-title>Reset Password</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 320px; padding-top: 20px">
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    :error-messages="errors.get('email')"
                    required
                    outlined
                    label="Email"
                    v-model="email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    :error-messages="errors.get('password')"
                    required
                    outlined
                    label="Password"
                    type="password"
                    v-model="password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    type="password"
                    label="Confirm Password"
                    outlined
                    v-model="password_confirmation"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain large @click="dialog = false"> Cancel </v-btn>
          <v-btn
            large
            color="teal lighten-1"
            class="white--text"
            depressed
            @click="reset"
            :disabled="isResetting"
          >
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import FormErrors from "./../../helpers/form-errors";
import { mapActions } from "vuex";

export default {
  data: () => ({
    errors: new FormErrors(),
    valid: false,
    dialog: false,
    email: "",
    password: "",
    password_confirmation: "",
    isResetting: false,
  }),
  mounted() {
    this.email = this.$route.query.email;
  },
  methods: {
    ...mapActions(["setAuthUser"]),
    reset() {
      const form = {
        token: this.$route.params.token,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
      };
      this.isResetting = true;
      window.axios
        .post(`/api/reset-password`, form)
        .then(() => {
          this.$toast.success("Password successfully reset.");
          window.location.href = '/login';
        })
        .catch((e) => {
          this.isResetting = false;
          this.errors.clear();
          if (e.response.status == 401) {
            if (e.response.data == "passwords.token") {
              this.$toast.error(
                "Token expired, please refrest and request another password reset."
              );
            }
            if (e.response.data == "passwords.user") {
              this.$toast.error("The email is not registered to the system.");
            }
          }
          if (e.response.status == 422) {
            this.errors.record(e.response.data.errors);
          }
        });
    },
  },
};
</script>

<style>
</style>