require("./bootstrap");

import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "./registerServiceWorker";
import VueRouter from "vue-router";
import store from "./store/index";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import VuetifyConfirm from "vuetify-confirm";

Vue.use(VueToast, {
  position: "top-right",
});
Vue.use(VuetifyConfirm, { vuetify });
Vue.use(VueRouter);

Vue.config.productionTip = false;

/** Styles */
import "@/assets/css/style.css";

/** Public pages */
import HomePage from "./pages/public/Home.vue";

/** Tokens */
import { getToken, deleteToken } from "./helpers/token";

/** Admin Pages */
import OrdersPage from "./pages/Admin/Orders/Index.vue";
import ClinicsPage from "./pages/Admin/Clinics/Index.vue";
import UsersPage from "./pages/Admin/Users/Index.vue";
import RegionsPage from "./pages/Admin/Regions/Index.vue";
import UrnTypesPage from "./pages/Admin/UrnTypes/Index.vue";
import SettingsPage from "./pages/Admin/Settings/Index.vue";
import DispositionsPage from "./pages/Admin/Dispositions/Index.vue";
import Forbidden from "./pages/Forbidden.vue";
import NotFound from "./pages/404.vue";

/** Components */

const routes = [
  {
    name: "Home",
    path: "/",
    component: HomePage,
    meta: {
      page_title: "",
      requiresAuth: false,
    },
  },
  {
    name: "Reset",
    path: "/password/reset/:token",
    component: HomePage,
    meta: {
      page_title: "",
      requiresAuth: false,
    },
  },
  {
    name: "Login",
    path: "/login",
    component: HomePage,
    meta: {
      page_title: "",
      requiresAuth: false,
    },
  },
  {
    name: "Orders",
    path: "/orders",
    component: OrdersPage,
    meta: {
      page_title: "Work Orders",
      page_icon: "mdi-clipboard-file-outline",
      requiresAuth: true,
    },
  },
  {
    name: "Clinics",
    path: "/clinics",
    component: ClinicsPage,
    meta: {
      page_title: "Clinics",
      page_icon: "mdi-domain",
      requiresAuth: true,
    },
  },
  {
    name: "Users",
    path: "/users",
    component: UsersPage,
    meta: {
      page_title: "Users Management",
      page_icon: "mdi-account-group",
      requiresAuth: true,
    },
  },
  {
    name: "Regions",
    path: "/regions",
    component: RegionsPage,
    meta: {
      page_title: "Regions",
      page_icon: "mdi-map-marker-outline",
      requiresAuth: true,
    },
  },
  {
    name: "Urn Types",
    path: "/urn-types",
    component: UrnTypesPage,
    meta: {
      page_title: "Urn types",
      page_icon: "mdi-text-box-outline",
      requiresAuth: true,
    },
  },
  {
    name: "Dispositions",
    path: "/dispositions",
    component: DispositionsPage,
    meta: {
      page_title: "Dispositions",
      page_icon: "mdi-playlist-minus",
      requiresAuth: true,
    },
  },
  {
    name: "Site Settings",
    path: "/site-settings",
    component: SettingsPage,
    meta: {
      page_title: "Settings",
      page_icon: "mdi-application-cog",
      requiresAuth: true,
    },
  },
  {
    name: "Forbidden",
    path: "/forbidden",
    component: Forbidden,
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
];

/** Axios interceptors */
window.axios.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers["Authorization"] = "Bearer " + getToken();
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

window.axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (!['Home', 'Reset', 'Login'].includes(router.currentRoute.name)) {
        deleteToken();
        store.dispatch("logoutUser");
        router.push({
          name: "Home",
        });
      }
    }

    if (error.response.status === 403) {
      router.push({
        name: "Forbidden",
      });
    }

    if (error.response.status === 500) {
      alert(
        "Something went wrong!, Please refresh the page and contact the developer."
      );
    }

    if (error.response.status === 404) {
      router.push({
        name: "NotFound",
      });
    }

    return Promise.reject(error);
  }
);

const router = new VueRouter({
  mode: "history",
  routes,
});

/** Router */
router.beforeEach((to, from, next) => {
  if (
    to.meta.requiresAuth &&
    !store.state.authUser.authenticated &&
    window.localStorage.getItem("token") == ""
  ) {
    next({ name: "Home" });
  }
  next();
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
