export default {
  state: {
    drawer: true,
  },
  mutations: {
    openDrawer(state) {
      state.drawer = true;
    },
    closeDrawer(state) {
      state.drawer = false;
    },
    setDrawer(state, payload) {
      state.drawer = payload;
    }
  },
  getters: {
    getDrawer: (state) => {
      return state.drawer;
    },
  },
};
