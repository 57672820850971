<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      width="950px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>{{ title }} Informative</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px">
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    :disabled="viewing"
                    :error-messages="errors.get('title')"
                    required
                    label="Title"
                    v-model="form.title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <span
                    class="theme--light v-label"
                    style="
                      font-family: 'Montserrat' !important;
                      font-size: 11px;
                      color: #000;
                    "
                    >Body</span
                  >
                  <vue-editor
                    v-if="!viewing"
                    v-model="form.body"
                    id="editor-body"
                  ></vue-editor>
                  <div v-else v-html="form.body"></div>
                </v-col>
                <v-col cols="12" class="pb-0" xs="12">
                  <v-menu
                    v-model="menu_date_effective_at"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="viewing"
                        :error-messages="errors.get('effective_at')"
                        required
                        clearable
                        v-model="form.effective_at"
                        label="Effective Date"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.effective_at"
                      @input="menu_date_effective_at = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="pb-0" xs="12">
                  <v-menu
                    v-model="menu_date_expires_at"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="viewing"
                        :error-messages="errors.get('expires_at')"
                        required
                        clearable
                        v-model="form.expires_at"
                        label="Expired On"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.expires_at"
                      @input="menu_date_expires_at = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    :disabled="viewing"
                    label="Sort Order"
                    v-model="form.sort_order"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain @click="close" large> Cancel </v-btn>
          <v-btn
            v-if="viewing"
            color="amber darken-2"
            class="white--text"
            :disabled="updating"
            depressed
            @click="viewing = false"
            large
          >
            Edit
          </v-btn>
          <v-btn
            v-else
            color="teal lighten-1"
            class="white--text"
            :disabled="updating"
            depressed
            @click="save"
            large
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import FormErrors from "./../helpers/form-errors";
import { mapState } from "vuex";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  computed: {
    ...mapState({
      authUser: (state) => state.authUser.user,
    }),
    title() {
      if (this.viewing) {
        return "Details: ";
      }
      return this.action;
    },
  },
  data: () => ({
    form: {},
    errors: new FormErrors(),
    dialog: false,
    updating: false,
    action: "Create",
    menu_date_effective_at: false,
    menu_date_expires_at: false,
    viewing: false,
  }),
  created() {
    this.form = this.initForm();
  },
  methods: {
    save() {
      if (this.action == "Create") {
        this.store();
      } else {
        this.update();
      }
    },
    edit(item) {
      this.action = "Edit";
      this.dialog = true;
      this.updating = true;
      window.axios.get(`/api/informatives/${item.id}`).then((r) => {
        this.updating = false;
        this.form = {
          id: r.data.id,
          title: r.data.title,
          body: r.data.body,
          effective_at: r.data.effective_at,
          expires_at: r.data.expires_at,
          sort_order: r.data.sort_order,
        };
      });
    },
    update() {
      this.updating = true;
      this.form.updated_by = this.authUser.id;
      window.axios
        .put(`/api/informatives/${this.form.id}`, this.form)
        .then(() => {
          this.updating = false;
          this.$toast.success("Informative updated.");
          this.close();
          this.$emit("reload");
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$toast.error("Please complete all required fields.");
            this.errors.record(e.response.data.errors);
          }
          this.updating = false;
        });
    },
    store() {
      this.updating = true;
      this.form.created_by = this.authUser.id;
      window.axios
        .post(`/api/informatives`, this.form)
        .then(() => {
          this.updating = false;
          this.$toast.success("Informative created.");
          this.close();
          this.$emit("reload");
        })
        .catch((e) => {
          if (e.response.status == 422) {
            this.$toast.error("Please complete all required fields.");
            this.errors.record(e.response.data.errors);
          }
          this.updating = false;
        });
    },
    close() {
      this.errors.clear();
      this.dialog = false;
      this.form = this.initForm();
      this.action = "Create";
    },
    initForm() {
      return {
        id: null,
        title: "",
        body: "",
        effective_at: null,
        expires_at: null,
        sort_order: null,
      };
    },
  },
};
</script>

<style>
#editor-body {
  height: 150px;
}
</style>