var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.filter),expression:"filter"}],staticClass:"mb-5",attrs:{"flat":""}},[_c('v-form',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","xs":"12"}},[_c('v-text-field',{attrs:{"clearable":"","dense":"","outlined":"","label":"Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","xs":"12"}},[_c('v-autocomplete',{attrs:{"clearable":"","dense":"","outlined":"","items":_vm.statuses,"label":"Status","item-text":"title","item-value":"title"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-5",attrs:{"color":"blue-grey darken-1","dark":"","depressed":""},on:{"click":_vm.search}},[_vm._v("Search")]),_c('v-btn',{attrs:{"dark":"","color":"grey","depressed":"","outlined":""},on:{"click":_vm.resetFilter}},[_vm._v("Reset Filter")])],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dispositions,"options":_vm.options,"server-items-length":_vm.totalDispositions,"footer-props":_vm.footerProps,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('record-activator',{attrs:{"item":item,"table":"dispositions"},on:{"reload":_vm.paginate}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"amber darken-2"},on:{"click":function($event){return _vm.edit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.remove(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}])}),_c('create-disposition',{ref:"createDispositionDialog",on:{"reload":_vm.paginate}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }