<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        :color="item.is_active ? 'green accent-3' : 'red accent-2'"
        v-bind="attrs"
        v-on="on"
        @click="updateActivation"
      >
        <v-icon>mdi-circle</v-icon>
      </v-btn>
    </template>
    <span>{{
      item.is_active ? "Click to deactivate" : "Click to activate"
    }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: ["item", "table"],
  methods: {
    updateActivation() {
      const method = this.item.is_active
        ? "deactivate-record"
        : "activate-record";
      window.axios
        .put(`/api/settings/${method}/${this.item.id}`, { table: this.table })
        .then(() => {
          this.$toast.success(
            this.item.is_active ? "Item deactivated." : "Item activated."
          );
          this.$emit("reload");
        });
    },
  },
};
</script>