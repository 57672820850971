<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    width="290px"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-card-title>Weight (lbs)</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 80px" class="pt-5">
        <v-text-field
          clearable
          dense
          outlined
          label="Enter Weight"
          type="number"
          v-model="form.weight"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain @click="modal = false" large> Cancel </v-btn>
        <v-btn
          color="teal lighten-1 white--text"
          depressed
          @click="updateWeight"
          large
          :disabled="!form.weight"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  data() {
    return {
      form: {},
      modal: false,
    };
  },
  methods: {
    show(item) {
      this.modal = true;
      this.form = JSON.parse(JSON.stringify(item));
    },
    updateWeight() {
      this.modal = false;
      const status = 3;
      const weight = this.form.weight;
      window.axios
        .put(`/api/orders/weight-in/${this.form.id}`, { status, weight })
        .then(() => {
          this.modal = false;
          this.$emit("reload");
        });
    },
  },
};
</script>