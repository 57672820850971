import Vue from "vue";
import Vuex from "vuex";

import authUser from "./modules/auth-user";
import users from "./modules/users";
import dispositions from "./modules/dispositions";
import urns from "./modules/urns";
import roles from "./modules/roles";
import clinics from "./modules/clinics";
import regions from "./modules/regions";
import countries from "./modules/countries";
import orders from "./modules/orders";
import sidebar from "./modules/sidebar";
import informatives from "./modules/informatives";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authUser,
    users,
    dispositions,
    urns,
    roles,
    clinics,
    regions,
    countries,
    orders,
    sidebar,
    informatives,
  },
  strict: true,
});
