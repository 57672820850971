export default {
  state: {
    countries: [],
  },
  mutations: {
    setCountries(state, payload) {
      state.countries = payload.countries;
    },
  },
  actions: {
    getCountries: ({ commit }, params) => {
      return new Promise((resolve, reject) => {
        window.axios
          .post(`/api/countries/filter`, params)
          .then((r) => {
            commit({
              type: "setCountries",
              countries: r.data.data,
            });

            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
