<template>
  <v-app id="inspire">
    <template v-if="['Home', 'Reset', 'Login'].includes($route.name)">
      <v-app-bar app color="white" flat>
        <v-img
          max-height="50"
          max-width="50"
          src="@/assets/fetchLogo.png"
        ></v-img>
        <h4 class="teal--text text--lighten-2">&nbsp; {{ siteName }}</h4>
        <v-spacer></v-spacer>
        <template v-if="authenticated">
          <span class="mr-5 d-none d-sm-flex"
            ><v-icon class="mr-2">mdi-phone</v-icon> Call {{ phone }}</span
          >
          <avatar-menu></avatar-menu>
        </template>

        <template v-else>
          <span class="mr-5 d-none d-sm-flex"
            ><v-icon class="mr-2">mdi-phone</v-icon> Call {{ phone }}</span
          >
          <v-btn
            class="ma-2"
            dark
            color="teal lighten-1"
            @click="showLoginForm"
            depressed
            >Login</v-btn
          >
        </template>
      </v-app-bar>
      <v-main class="grey lighten-3">
        <div id="header-image"></div>
        <v-container>
          <v-row class="d-flex d-sm-none">
            <v-col cols="12">
              <v-btn
                v-if="authenticated"
                class="ma-2"
                dark
                color="teal lighten-1"
                text
                @click="downloadForm"
                depressed
                ><v-icon>mdi-tray-arrow-down</v-icon>&nbsp; Download Form</v-btn
              >
              <p class="ml-6 mt-5"><v-icon class="mr-2">mdi-phone</v-icon> Call {{ phone }}</p>
            </v-col>
          </v-row>
          <v-row v-for="(informative, i) in informatives" :key="i">
            <v-col cols="12">
              <v-card flat class="grey lighten-3">
                <h1>{{ informative.title }}</h1>

                <v-divider class="my-5" style="width: 200px"></v-divider>

                <div class="my-5" v-html="informative.body"></div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </template>
    <template
      v-if="authenticated && !['Home', 'Reset', 'Login'].includes($route.name)"
    >
      <sidebar v-if="isAuthAdmin || isAuthClinicUser" />
      <v-main>
        <app-bar />
        <v-container class="py-8 px-6" fluid>
          <router-view />
        </v-container>
      </v-main>
    </template>
    <login ref="loginDialog"></login>
    <reset-password ref="resetPasswordDialog"></reset-password>
  </v-app>
</template>

<script>
import Login from "./Login.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import AvatarMenu from "../../components/AvatarMenu.vue";
import AppBar from "../../components/layouts/AppBar.vue";
import Sidebar from "../../components/layouts/Sidebar.vue";
import ResetPassword from "./ResetPassword.vue";
import { getToken } from "./../../helpers/token";

export default {
  components: { Sidebar, AppBar, Login, AvatarMenu, ResetPassword },
  data() {
    return {
      informatives: [],
    };
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.authUser.authenticated,
      user: (state) => state.authUser.user,
      settings: (state) => state.informatives.settings,
    }),
    ...mapGetters(["isAuthAdmin", "isAuthClinicUser", "getSettingValue"]),
    phone() {
      return this.getSettingValue("phone");
    },
    siteName() {
      return this.getSettingValue("site_name");
    },
  },
  methods: {
    ...mapActions(["getInformatives", "getSettings"]),
    downloadForm() {
      window.axios
        .post(
          `/api/orders/generate-form`,
          { is_blank_form: true, id: 0 },
          { responseType: "blob" }
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let filename = "authorization-form.pdf";
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    showLoginForm() {
      this.$refs.loginDialog.dialog = true;
    },
  },
  mounted() {
    this.getInformatives({ valid: true }).then((r) => {
      this.informatives = r.data.data;
    });
    this.getSettings();

    if (!getToken() && this.$route.name == "Reset") {
      this.$refs.resetPasswordDialog.dialog = true;
    }

    if (!getToken() && this.$route.name == "Login") {
      this.showLoginForm();
    }
  },
};
</script>

<style>
/** Headings **/
#header-image {
  background: url("./../../assets/background.jpg") no-repeat top center;
  background-size: 100%;
  height: 500px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 600px) {
  #header-image {
  height: 250px;
}
}
</style>